import React, {useState, useEffect } from 'react'
import './DefaultView.css'
import activeData from '../../../../images/dataActive.png'
import foneNumber from '../../../../images/data2.png'
import userNumber from '../../../../images/data.png'
import TransactionTable from './TransactionTable';

const DefaultView = () => {
   const subscribInfoRaw = localStorage.getItem('subscriberInformation');
   const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
   const [displaynumber, setDisplayNumber] = useState('')

   useEffect(() => {
    const hasMultipleEntries = subscribInfoProcessed.phoneDetails.length > 1;
  
    let MSISDN;
  
    if (hasMultipleEntries) {
      const filtered = subscribInfoProcessed.phoneDetails.filter(msisdn => msisdn.MSISDN === '2347120001199');
      
      MSISDN = filtered.length > 0 ? filtered[0].MSISDN : subscribInfoProcessed.phoneDetails[0].MSISDN;
    } else {
      MSISDN = subscribInfoProcessed.phoneDetails[0].MSISDN;
    }
  
    setDisplayNumber(MSISDN);
  }, [subscribInfoProcessed]);
     
  return (
    <div className="parentDefault">
      <div className="parentCardContainer">
        <div className="userInfoCard2"></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        <div className="userInfoCard2" style={{background: 'white'}}></div>
        {/* <div className="userInfoCard2" style={{background: 'white'}}></div> */}
        {/* <div className="userInfoCard"></div> */}
        <div className="userInfoCard">
          <div className="userCardIcon">
            <img src={userNumber} alt="" />
          </div>
          <div className="userCardTitle">
            <span className="itemName">MSISDN</span>
            <h2 className="dashBoardItem">{displaynumber.replace(/^234/, '0')}</h2>
          </div>
        </div>
        <div className="userInfoCard">
          <div className="userCardIcon">
            <img src={foneNumber} alt="" />
          </div>
          <div className="userCardTitle">
            <span className="itemName">Airtime Balance</span>
            <h2 className="dashBoardItem">
              {subscribInfoProcessed.phoneDetails[0].KYC === 'No' ? '0 NGN' : 0}
            </h2>
          </div>
        </div>
        <div className="userInfoCard">
          <div className="userCardIcon">
            <img src={activeData} alt="" />
          </div>
          <div className="userCardTitle">
            <span className="itemName">Data Balance</span>
            <h2 className="dashBoardItem">
              {' '}
              {subscribInfoProcessed.phoneDetails[0].KYC === 'No' ? '0 Kb' : '0GB'}
            </h2>
          </div>
        </div>
      </div>
      <TransactionTable />
    </div>
  );
}

export default DefaultView