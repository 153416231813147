import React from 'react'
import moment from 'moment';

const TopupHistoryTable = ({ topUpHistory }) => {
  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">MSISDN</th>
            <th scope="col">Top ID</th>
            <th scope="col">Top up amount</th>
            <th scope="col">Top up by</th>
            <th scope="col">Top up date</th>
            <th scope="col">Top up status</th>
          </tr>
        </thead>
        <tbody>
          {topUpHistory &&
            topUpHistory.map((history, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <td>
                  <h4 className="productPurchaseName">{history.MSISDN.replace(/^234/,0)}</h4>
                </td>
                <td>
                  <span>{history.topupId}</span>
                </td>
                <td>
                  <span>{history.amount}</span>
                </td>
                <td>
                  <span>{history.createdBy}</span>
                </td>
                <td>
                  <span>{moment(history.createdDateTime).format('MMM Do, YYYY, h:mm A')}</span>
                </td>
                <td>
                  <span style={{ color: 'green' }}>success</span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopupHistoryTable;