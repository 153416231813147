import React from 'react';
import './Devices.css';
import virtualReality from '../../images/vitelVirtual.jpg';
import braceLet from '../../images/smartbracelet2.png';
import girlDevice from '../../images/girlDevice.png';
import { useNavigate } from 'react-router-dom';

const Devices = () => {
  const navigate = useNavigate();

  const navigateEcommerce = () => {
    navigate('/e-commerce');
  };

  return (
    <div className="parentDeviceSection">
      <div className="deviceSectionContainer">
        <div className="deviceSectionHeader">
          <span className="deviceHeaderTitle">Devices</span>
          <div className="deviceButton">
            <button onClick={navigateEcommerce}>See All Devices</button>
          </div>
        </div>
        <div className="mainDevicesList">
          <div className="virtual">
            <img src={virtualReality} alt="" />
          </div>
          <div className="bracelet">
            <img src={braceLet} alt="" />
          </div>
          <div className="braceletSecond">
            <img src={girlDevice} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;
