import React, {useState} from 'react'
import { IoMdNotifications } from "react-icons/io";
import { BiNotification, BiSearch } from 'react-icons/bi'
import './ContentHeader.css'


const ContentHeader = () => {
    
  return (
    <div className="content-header">
      
      <h1 className="header-title">My Account Management</h1>
      <div className="header-activity">
        {/* <div className="notify">
          <IoMdNotifications className="icon" />
        </div> */}
      </div>
    </div>
  );
}

export default ContentHeader