import React from 'react';
import { FcCollaboration } from 'react-icons/fc';
import './PartnerFunctions.css';
import PartnerForm from '../PartnerForm/PartnerForm';

const PartnerFunctions = () => {
  return (
    <div className="parentPartnerFunction">
      <div className="partnerFunction">
        <div className="partnerFunctionImg">
          <img
            src="https://img.freepik.com/free-vector/hand-drawn-flat-design-international-trade_23-2149180270.jpg"
            alt=""
          />
        </div>
        <div className="partnerFunctionText">
          <h3>Benefit of vitel partner</h3>
          <div className="partnerFunctionInfo">
            <p>
              As a partner with Vitel Wireless, you become a part of our selling and distribution network, providing
              top-of-the-line telecom solutions to our customers in your market area.
            </p>
            <p>
              The reseller network is part of Vitel Wireless core strategy, where your success is closely tied to our
              success. Our strategy is based on a “Partner First” approach, prioritizing the growth of our partners’
              businesses, in other words, we don’t compete with our partners when it comes to their market zone.
            </p>
            <p>
              As such, we are committed to aiding our resellers across Nigeria to achieve their business goals and
              establish long-term relationships built on common goals and trust.
            </p>
          </div>
        </div>
      </div>

      <div className="partnerFunction" style={{ paddingBottom: '2%' }}>
        <div className="partnerFunctionText">
          <h3>Become a Partner</h3>
          <div className="partnerFunctionInfo">
            <p>
              Are you ready to be a part of the Mission to make Nigeria better? Register as a Partner today and join our
              mission to create wealth in your local market. After your registration, you will be contact by one of our
              Partner Account Managers to evaluate your qualifications and area of interest.
            </p>
          </div>
        </div>
        <div className="partnerFunctionImg">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTZhQPoubKjvwNPL3fwV2dtvKKu96iQm5LDjA&s"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerFunctions;
