import HomeNavComponent from './home-nav-component';
import LearnMoreNav from './learnmore-nav';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFectch from '../utility/getFunction';
import Footer from './footer';
import Table from 'react-bootstrap/Table';
import secureme from '../images/yellow_logo1.jpg';
import ogaboiz from '../images/oga.jpg';
import Button from 'react-bootstrap/Button';
import { MdAddBox, MdDataSaverOn } from 'react-icons/md';
// import { MdDataSaverOn } from "react-icons/md";
import './LearnMore.css';
import { useForm } from 'react-hook-form';
import { vitelWirelessAxios } from '../utility/axios';


let renderCount = 0;

const LearnMore = () => {
  const [lgaId, setLgaId] = useState('');
  const [localGvt, setLocalGvt] = useState([]);
  const [state, setState] = useState('');
  const [show, setShow] = useState(false);
  const [retail, setRetail] = useState('');
  const [resell, setResell] = useState('');
  const [organization, setOrganization] = useState('');
  const [prePurchase, setPrePurchase] = useState('');
  const [vitelAccount, setVitelAccount] = useState('');
  const [formItems, setFormItems] = useState([{ value: '' }]);
  const [entries, setEntries] = useState([]);
  const [lga, setLga] = useState('');
  const [stateName, setStateName] = useState('');
  const[stateId, setStateId] = useState('');
  const [mainState, setMainState] = useState("")

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/reserve-number');
  };

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();
  renderCount++;

  const {
    data: states,
    isPending: statesIsPending,
    error: statesError
  } = useFectch('generals/states');
  const {
    data: localGvtCouncil,
    isPending: localGvtIsPending,
    error: localGvtError
  } = useFectch('generals/local_govt');

  const onChangeHandler = (e) => {
    const newStateId = e.target.value;
    setState(newStateId);
    setMainState(newStateId)


    const filterLga = localGvtCouncil?.filter(
      (lga) => lga.stateId === newStateId
    );
    setLocalGvt(filterLga);
  };
     
const filterStateName  = (()=>{
const filterStateNames = states.filter((data)=>(
        data.stateId === state
))
setStateName(filterStateNames)
console.log("filterStateNames",filterStateNames);



}        
);

  const handleLocalGvt = (e) => {
    const lgaIdGet = e.target.value;
    setLgaId(lgaIdGet);
  };

  const handleAddEntry = () => {

    const selectedState = states.find((data)=>(
           data.stateId === state
    ))
    if (state && lga) {
      const newEntry = { stateId: selectedState.stateId, stateName: selectedState.name, lga };
      setEntries([...entries, newEntry]);
      setState('');
      setLocalGvt('');
    }
    
  };

  // Remove entry from the table
  const handleRemoveEntry = (index) => {
    const updatedEntries = entries.filter((_, i) => i !== index);
    setEntries(updatedEntries);
  };




  const handleSubmitData = async (data) => {
  
    const allData = {
      ...data,
      partnerType: retail || resell || organization,
      purchasetype: prePurchase || vitelAccount,
      state: mainState,
      salesArea: entries
     
    }
console.log("data", allData);
    await vitelWirelessAxios.post("registrations", allData)
      .then((res) => {
       


      }).catch((err) => {

        console.log("err", err)
       

      })

  }






  return (
    <>
      <LearnMoreNav />

      <div className='parentLearnMore'>
        <div className='learnMoreHero'>
          <div className='learnMorecontainer2'>
            <h2 className='learnMoreHeader'>
              Vitel
              <span className='ourPartners'> Partners</span> Program
            </h2>

            <p className='learnMoretxt'>
              Thank you for choosing Vitel Wireless, a new GSM type company. We
              promise to do things differently. We will allocate your choice of
              your numbers and deliver the physical SIM card to you very soon.
            </p>
          </div>
        </div>

        <div className='partnerFormReserve'>
          <div className='grid'>
            <form action=''onSubmit={handleSubmit((data, event) => {
                        // event.target.reset()
                        console.log("seedata", data);
                        handleSubmitData(data)
                      })} >
              <div className='firstParentRadio'>
                <div className='firstRadioSection'>
                  <legend>
                    Let’s get started, please select one of these options{" "}
                  </legend>
                  <div class='form__group'>
                    <input
                      type='radio'
                      checked
                      id="berries_1"
                      onClick={() => setRetail("retail")}
                      className="input"
                      name="berries"
                    />
                    <label for='berries_1'>
                      I am company with retail outlets.
                    </label>
                  </div>
                  <div class='form__group'>
                    <input
                      type="radio"
                      id="berries_2"
                      onClick={() => setResell("resell")}
                      className="input"
                      name="berries"
                    />
                    <label for='berries_2'>
                      I am an individual that wants to resell Sim/Recharge.
                    </label>
                  </div>
                  <div class='form__group'>
                    <input
                      type="radio"
                      id="berries_2"
                      onClick={() => setOrganization("organization")}
                      className="input"
                      name="berries"
                    />
                    <label for='berries_2'>
                      We are organization like church/NGO to resell to members
                      only.
                    </label>
                  </div>
                </div>

                <div className='secondRadioSection'>
                  <fieldset>
                    <legend>
                      Tell us more about your ideas, please select one of the
                      following
                    </legend>
                    <div class='form__group'>
                      <input
                        type='radio'
                        checked
                        id="berries_1"
                        onClick={() => setPrePurchase("prepurchase")}
                        className="input"
                        name="ben"
                      />
                      <label for='berries_1'>
                        I/We can pre-purchase stock from Vitel at discounts and
                        sell retail price.
                      </label>
                    </div>
                    <div class='form__group'>
                      <input
                        type="radio"
                        id="berries_2"
                        onClick={() => setVitelAccount("vitelAccount")}
                        className="input"
                        name="ben"
                      />
                      <label for='berries_2'>
                        I/We would like to sell and remit amount into Vital
                        accounts.
                      </label>
                    </div>
                    <p style={{ color: "#32C1F6" }}>
                      {" "}
                      Priorities and premium choicy locations are given to the
                      first category above.
                    </p>
                  </fieldset>
                </div>
              </div>

              <div className='parentFormField22'>
                <div className='areaTable'>
                  <div className='areaTableHead'>
                    What geographical areas/state you operate (or wants to
                    operate). You can add up to 10.
                  </div>
                  <div className='setDivide'>
                    <div>
                      <label className='labelText'>
                        Select State <span className='red-text'>*</span>{" "}
                      </label>
                      <select
                        className=" selectmain classic22"
                        onChange={onChangeHandler} onClick={filterStateName}
                       
                      >
                        <option>Choose...</option>
                        {states.map((state) => (
                          <option  key={state.stateId} value={state.stateId}> {state.name} </option>
                        ))}

                        {states && <option>Loading...</option>}
                      </select>
                    </div>
                    <div>
                      <label className='labelText'>
                        Select area <span className='red-text'>*</span>
                      </label>
                      <select
                        className=" selectmain  classic22"
                        onChange={(e) => setLga(e.target.value)}
                      >
                        <option>Choose...</option>
                        {localGvt &&
                          localGvt.map((lga, i) => (
                            <option
                              key={i.id}
                              value={lga.name}
                              name="townOfResidence"
                            >
                              {" "}
                              {lga.name}
                            </option>
                          ))}
                        {localGvtIsPending && <option>Loading...</option>}
                      </select>
                    </div>
                  </div>

                  <div className="partnerAddBtn">
                    <button className="addPartnerMainBtn" role="button" onClick={handleAddEntry} disabled={!state || !localGvt}>
                      <MdAddBox />
                      Add
                    </button>
                  </div>




                
                    <table className="table partnerTable">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Selected Areas</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      {entries.length > 0 ? (
                      <tbody>
                        {entries.map((entry, index) => (
                          <tr key={index}>
                            <td>{index+1} </td>
                            <td>{entry.stateName}/{entry.lga}</td>
                            
                            <td>
                              <button className="removeARea" onClick={() => handleRemoveEntry(index)}>Remove</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                   

                  ) : (
                    <p className='textPushCEnter'>Select one or more geographical area</p>
                  )}

                  </table>
                </div>

                <div className='partnerFormFieldDiv'>
                  <div className='sectionHeader'>
                    We need some information in case we need to contact you for
                    follow up.{" "}
                  </div>
                  <Form>
                    <Row>
                      <Col>
                        <label>
                          Email <span className='red-text'>*</span>
                        </label>
                        <Form.Control placeholder="Email..."  {...register("email", {
                          required: "email is required", maxLength: {
                            message: "email is required"
                          }
                        })} />
                        <span className="cum-error">{errors.email?.message}</span>
                      </Col>
                      <Col>
                        <label>
                          Phone Number <span className='red-text'>*</span>
                        </label>
                        <Form.Control placeholder="Phone number..." {...register("phone", {
                          required: "Phone number is required", maxLength: {
                            message: "phone number is required"
                          }
                        })} />
                        <span className="cum-error">{errors.phone?.message}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>
                          First Name <span className='red-text'>*</span>
                        </label>
                        <Form.Control placeholder="First name" {...register("firstName", {
                          required: "First name is required", maxLength: {
                            message: "first name is required"
                          }
                        })} />
                        <span className="cum-error">{errors.firstName?.message}</span>
                      </Col>
                      <Col>
                        <label>
                          Last Name <span className='red-text'>*</span>
                        </label>
                        <Form.Control placeholder="Last name" {...register("lastName", {
                          required: "Last name is required", maxLength: {
                            message: "Last name is required"
                          }
                        })} />
                        <span className="cum-error">{errors.lastName?.message}</span>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label>Company Name <span className="red-text">*</span>

                        </label>
                        <Form.Control placeholder="Company name" {...register("companyName", {
                          required: "Company name is required", maxLength: {
                            message: "Last name is required"
                          }
                        })} />
                        <span className="cum-error">{errors.companyName?.message}</span>
                      </Col>
                      <Col>
                        <label>State <span className="red-text">*</span>

                        </label>
                        <select
                          className='selectDDiv'
                          onChange={onChangeHandler}
                        >
                          <option>Choose...</option>
                          {states.map((state) => (
                            <option value={state.stateId}>
                              {" "}
                              {state.name}{" "}
                            </option>
                          ))}

                          {states && <option>Loading...</option>}
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label>Local Government <span className="red-text">*</span>

                        </label>
                        <select
                          className='selectDDiv'
                          onChange={handleLocalGvt}
                        >
                          <option>Choose...</option>
                          {localGvt &&
                            localGvt.map((lga, i) => (
                              <option
                                key={i.id}
                                {...register("localGovt")}
                                name="townOfResidence"
                              >
                                {" "}
                                {lga.name}
                              </option>
                            ))}
                          {localGvtIsPending && <option>Loading...</option>}
                        </select>
                      </Col>
                      <Col>
                        <label> Street Number/name <span className="red-text">*</span>

                        </label>
                        <Form.Control placeholder="Street name"  {...register("streetName")} />
                      </Col>
                    </Row>
                    <div className='GPSinfo'>
                      We use GPS AI to match potential partners with more
                      profitable locations. If you can, use Google or WhatsApp
                      to get your GPS (Latitude and Longitude) and input it
                      below. No worries if you don’t know how to:
                    </div>

                    <Row>
                      <Col>
                        <label>Latitude </label>
                        <Form.Control placeholder="Latitude" {...register("latitude")} />
                      </Col>
                      <Col>
                        <label>Longitude </label>
                        <Form.Control placeholder="Longitude" {...register("longitude")} />
                      </Col>
                    </Row>
                  </Form>
                  <div className='formSaveButton'>
                    <button
                      type="submit"
                      className="addPartnerMainBtn "
                      
                    >
                      <MdDataSaverOn />
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='lg'
      >
        <Modal.Header>
          <Modal.Title>Partnership Confirmation </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='setTexts'>
            {" "}
            It is great that you considering a long relationship with us. We are
            excited and we look forward to having you as our partner. Welcome to
            Vitel Wireless. In the meantime, if need anything we are just a tap
            away, check out the FAQ or contact Support.
          </p>

          <p className='setTexts'>
            {" "}
            Your request reference number is:{" "}
            <span className='setTextsNum'>Xy676575</span>{" "}
          </p>
          <p className='setTexts'>
            {" "}
            For the following locations:{" "}
            <span className='setTextsNum'>Lagos/Etiosa, Anambra/Aniocha</span>
          </p>
          <p className='setTexts' style={{ color: "#87CEEB" }}>
            {" "}
            Note: Use your camera to snap and save it.
          </p>

          <div>
            <p className='setTexts'>
              Would you like to create an only account and start using some of
              our features that are already available on smartphones. The
              following features are already available
            </p>
          </div>
          <div className='setlogolo'>
            <div className='ogaSecure'>
              <img src={secureme} className='secureImage' />
              <p>Join us in shaping a safer world with SecureMe</p>
            </div>
            <div className='ogaSecure'>
              <img src={ogaboiz} className='secureImage' />
              <p>
                LBS/GPS-based clock-in system that automates the recording of
                your work hours.
              </p>
            </div>
          </div>
          <div>
            You can join any of our trending communities, to improve your
            hustle. Click on any below.
            <ul className='listhair'>
              <li>Marketing 101</li>
              <li>Support my hustle</li>
              <li>Cars</li>
              <li>Automobiles</li>
              <li>Naira</li>
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>







      <div className="footerSet">
        <Footer />
      </div>
    </>
  );
};
export default LearnMore;
