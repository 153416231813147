import { CiInstagram } from 'react-icons/ci';
import './footer.css';
import { FaFacebookSquare, FaQuestionCircle, FaTiktok } from 'react-icons/fa';
import { FaXTwitter, FaSquareInstagram } from 'react-icons/fa6';

import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const navigateBottomMenu = () => {
    navigate('/footer-menu');
  };
  const navigateHome = () => {
    navigate('/');
  };

  const handleDataPlanPage = () => {
    navigate('/data-plan');
  };

  return (
    <>
      <div className="footerSection">
        <div className="waves">
          <div className="wave" id="wave1"></div>
          <div className="wave" id="wave2"></div>
          <div className="wave" id="wave3"></div>
          {/*<div className="wave" id="wave4"></div> */}
        </div>
        <ul className="socialIcon">
          <li>
            <a href="">
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a href="">
              <FaTiktok />
            </a>
          </li>
          <li>
            <a href="">
              <FaXTwitter />
            </a>
          </li>
          <li>
            <a href="">
              <FaSquareInstagram />
            </a>
          </li>
        </ul>

        <ul className="menuItem">
          <li onClick={navigateHome}>
            <Link to='/'>Home</Link>
          </li>
          <li onClick={navigateBottomMenu}>
            <Link to='/footer-menu'>FAQ</Link>
          </li>
          <li onClick={navigateBottomMenu}>
            <Link to='/footer-menu'>About</Link>
          </li>
          <li onClick={navigateBottomMenu}>
            <Link to='/footer-menu'>Our Services</Link>
          </li>
          <li onClick={navigateBottomMenu}>
            <Link to='/footer-menu'>Partners</Link>
            
          </li>
        </ul>
        <p className="footerParagr">©2024 vitel wireless Limited | All rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
