import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TbRecharging } from "react-icons/tb";
import * as Yup from "yup";
import { useFormik } from "formik";
import { vitelWirelessAxios } from "../../utility/axios";

import "./TopUp.css";

const TopUp = () => {
  const [subscriberInfo, setSubscriberInfo] = useState({});
  const [allowRecharge, setAllowRecharge] = useState(false);
  const [airTimeError, setAirtimeErro] = useState(false);
  const navigator = useNavigate();

  // const [airTimeTopUpObj, setAirTimeTopUpObj] = useState()

  const formik = useFormik({
    initialValues: {
      MSISDN: "",
      amount: "",
    },
    validationSchema: Yup.object({
      MSISDN: Yup.string()
        .matches(/^[0-9]+$/, "MSISDN must contain only numbers")
        .required("MSISDN is required"),
      amount: Yup.string()
        .matches(/^[0-9]+$/, "amount must contain only numbers")
        .required("amount is required"),
    }),

    onSubmit: async (values) => {
      const formattedMSISDN = values.MSISDN.replace(/^0/, '234');
      const MSISDN = {
        MSISDN: formattedMSISDN,
      };
      await vitelWirelessAxios
        .post("/generals/getSubscriberData", MSISDN)
        .then((res) => {
          console.log("res", res);
          if (res.data.message == "Customer MSISDN Found") {   
            setSubscriberInfo(res.data.customerData);
            setAllowRecharge(true);
            handleTopUpCredit(res.data.customerData);
            console.log("res", res);
          } else if (res.data.message !== "Customer MSISDN Found") {
            setAirtimeErro(true);
            console.log("fail to load");
          } else {
            // notifySuccess(res, values);
          }
        })
        .catch((err) => console.log(" Login unsuccessfull", err));

      // setLoader(false)
    },
  });

  const handleTopUpCredit = (subscriberInfo) => {
    navigator("/air-time-top-up", {
      state: {
        amount: formik.values.amount,
        MSISDN: formik.values.MSISDN,
        subscriberInfo: subscriberInfo,
      },
    });
  };

  return (
    <>
      <div className='getStartedBtnDiv'>
        <form className='parentQuicktop' onSubmit={formik.handleSubmit}>
          {airTimeError ? (
            <span className='inValidNumError'>
              the number you entered is not a valid vitel number
            </span>
          ) : null}
          <div className='adjustedErroDiv'>
            <div className='topupTitle'>Quick Top Up</div>

            <div className='numberInput'>
              <label htmlFor=''>Vitel Number</label>
              <input
                type='text'
                placeholder='Enter Vitel number'
                value={formik.values.MSISDN}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='MSISDN'
                id='MSISDN'
              />

              {formik.touched.MSISDN && formik.errors.MSISDN ? (
                <p className='error'>{formik.errors.MSISDN}</p>
              ) : null}
            </div>
            <div className='numberInput'>
              <label htmlFor=''>Amount</label>
              <input
                type='text'
                placeholder='Enter amount'
                value={formik.values.amount.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name='amount'
                id='amount'
              />
              {formik.touched.amount && formik.errors.amount ? (
                <p className='error'>{formik.errors.amount}</p>
              ) : null}
            </div>
            <div className='rechargeNowBtnDiv'>
              <button className='getStartedBtn' type='submit'>
                <TbRecharging className="rechargeIcon" />
                Recharge Now
              </button>
            </div>
          </div>
        </form>
        
      </div>

      {/* <div
        class='modal fade'
        id='successModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabindex='-1'
        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='staticBackdropLabel'>
                Airtime Top up Successful
              </h1>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            {airTimeError ? (
              <div class='modal-body'>
                Your airtime top-up of <span>₦ {formik.values.amount}</span> to{" "}
                <span className='errorMessage'>{formik.values.MSISDN}</span> was
                not successful.
              </div>
            ) : (
              <div class='modal-body'>
                Your airtime top-up of ₦ {formik.values.amount} to{" "}
                <span className='errorMessage'>{formik.values.MSISDN}</span> was
                successful.
              </div>
            )}

            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-primary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TopUp;
