import React, { useState, useEffect } from 'react';

import topUpImg from '../../images/mobileview.jpg';
import { PaystackButton } from 'react-paystack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { vitelWirelessAxios } from '../../utility/axios';
import './AirTimeTopConfirmsecond.css';


const AirTimeTopConfirm = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { amount, MSISDN, subscriberInfo } = location.state || {};
  // const [allowRecharge, setAllowRecharge] = useState(false);
  const [airTimeError, setAirtimeErro] = useState(false);
  const [payReference, setPayReference] = useState();
  var airTimeTopUpObj;

  useEffect(() => {
    document.title = amount ? `Vitel Wireless | ${amount} Airtime top up` : 'Airtime top up';
  }, [amount]);

  // console.log("subscriberInfo", subscriberInfo);

  const handSubscriberInfo = reference => {
    setPayReference(reference.reference);
    const payStackObj = {
      MSISDN: MSISDN,
      productId: 'ZK' + amount,
      amount: amount,
      userName: MSISDN,
      paystackPaymentReference: reference.reference,
    };

    airTimeTopUpObj = payStackObj;
  };

  const handlePaystackSuccessAction = async reference => {
    handSubscriberInfo(reference);
    // setLoadingButton(true);
    // console.log('allValue', airTimeTopUpObj);
    const payStackRef = airTimeTopUpObj.paystackPaymentReference;
    await vitelWirelessAxios
      .post('/generals/handleTopUpCredit', airTimeTopUpObj)
      .then(res => {
        console.log('success topup message', res);
        let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
        myModal.show();
        document.getElementById('modalCloseButton').addEventListener('click', () => {
          handleNavigateSuccess(amount, MSISDN, subscriberInfo, payStackRef);
        });
      })
      .catch(err => {
        setAirtimeErro(true);
        console.log('top up error');
      });
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // setEmail('')
    //allValue = " ";
  };

  //

  const config = {
    reference: new Date().getTime().toString(),
    email: subscriberInfo?.emailAddress,
    amount: amount * 100,
    // currency: currencyCode,
    publicKey: 'pk_test_7c1eddc66acb8a19f5d6ea061840ce18005d7ddc',

    // publicKey: "pk_live_b3304b8274925a6dd5479dc6972a3ce6adde2396",
  };

  const componentProps = {
    ...config,
    text: 'Recharge Now',
    onSuccess: reference => {
      handlePaystackSuccessAction(reference);
    },
    onClose: handlePaystackCloseAction,
  };
 

  const handleNavigateSuccess = (amount, MSISDN, subscriberInfo, payReference) => {
    navigation(`/quick-topup-confirmation`, {
      state: {
        amount: amount,
        MSISDN: MSISDN,
        subscriberInfo: subscriberInfo,
        paystackPaymentReference: payReference,
      },
    });
  };

  return (
    <div className="parentAirtimeTopup">
      <div className="secondLayerTopUp">
        <div className="topupIMGDiv">
          <img src={topUpImg} alt="" />
        </div>
        <div className="topUpMainDiv">
          <h3>Air time top up request details</h3>
          <table className="table ">
            <thead></thead>
            <tbody>
              <tr>
                <th scope="row">Full Name</th>
                <td>
                  {subscriberInfo.lastName} {subscriberInfo.firstName}
                </td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{subscriberInfo?.emailAddress}</td>
              </tr>
              <tr>
                <th scope="row">Phone Number</th>
                <td>{MSISDN}</td>
              </tr>
              <tr>
                <th scope="row">Amount</th>
                <td colspan="2">{amount}</td>
              </tr>
              <tr>
                <th scope="row">Discount</th>
                <td colspan="2">0</td>
              </tr>
              <tr>
                <th scope="row">Total</th>
                <td colspan="2">{amount}</td>
              </tr>
            </tbody>
          </table>
          <div className="payNowDiv">
            <PaystackButton
              className="getStartedBtn"
              {...componentProps}
              // disabled={isFieldsEmpty()}
            />
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Airtime Top up Successful
              </h1>
             
            </div>
            {airTimeError ? (
              <div className="modal-body">
                Your airtime top-up of <span>₦ {amount}</span> to <span className="errorMessage">{MSISDN}</span> was not
                successful.
              </div>
            ) : (
              <div className="modal-body">
                Your airtime top-up of ₦ {amount} to <span className="errorMessage">{MSISDN}</span> was successful.
              </div>
            )}

            <div className="modal-footer">
              <button
                onClick={() => handleNavigateSuccess(amount, MSISDN, subscriberInfo, payReference)}
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                id="modalCloseButton"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AirTimeTopConfirm;
