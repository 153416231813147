import { FaCartPlus, FaHome } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import logo1 from "../images/Public site size.png";

const LearnMoreNav = () => {
  const navigate = useNavigate();

  const navigateLearnMore = () => {
    navigate("/learn-more");
  };
  const navigateReservedNumber = () => {
    navigate("/reservedNumberCart");
  };

  const navigateHome = () => {
    navigate("/");
  };

  return (      
    <>
      <div className='firCover'>
        <div className='first-div container'>
          <div style={{ fontWeight: "bold", color: "white" }}>
            {" "}
            <img className='imageLOgo' src={logo1} /> Intereted in selling Vital
            wireless in your area?
          </div>

          <button
            className='button-10'
            role='button'
            onClick={navigateLearnMore}
          >
            Learn more{" "}
          </button>

          <div>
            <FaCartPlus
              size={25}
              onClick={navigateReservedNumber}
              color='white'
            />{" "}
          </div>
          <div>
            <FaHome size={25} onClick={navigateHome} color='white' />{" "}
          </div>
          <div className='dropdown dropdown_hideshow'>
            <span>
              <IoMdMenu color='white' size={25} />
            </span>
            <div className='dropdown-content'>
              <a href='#'>Need help! call: 070-1701-1111</a>
              <a href='#'>WhatsApp: 07120000001</a>
              <a href='#'>Chat Online</a>
              <a href='#'>Email: customer.love @vitelwireless.com</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LearnMoreNav;
