import React from "react";
import "./VitelFeatures.css";
import freedom from '../../images/freedom.png'
import smartgsm from '../../images/smartgsm.png'
import edutainment from '../../images/edutainment .png'
import safty from '../../images/familysafety.png'
import familySafty from "../../images/vitelfeatures/1.png"
import smartGSM from "../../images/vitelfeatures/2.png"
import roamfreedom from "../../images/vitelfeatures/3.png"
import edutainmentPlay from "../../images/vitelfeatures/4.png"


const VitelFeatures = () => {
  return (
    <div className='parentVitelFeatures'>
      <div className='mainvitelFeature'>
        <div className='vitelFeatureHeader'>
          <div className='vitelFeatureDiv'>
            Extra Vitel Features
            <hr />
          </div>
        </div>

        <div className='vitelCardinal'>
          <div className='vitelCardinalMain'>
            <div className='seconLayerDiv'>
              {/* <img src={familySafty} alt='' /> */}
              <div className='featureimageDiv'>
                <img src={safty} alt='' />
              </div>
              <div className='featureTextDiv'>
                <p className='productName'>
                  Family Safety <br />
                  Features{" "}
                  <span className='pointSlogan'>
                    Included with every phone{" "}
                  </span>
                </p>
                <p className='productDescript'>
                  <ul>
                    <li>Share Location with family members.</li>
                    <li>Activate SOS during danger.</li>
                    <li>Share data with police when needed.</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className='seconLayerDiv'>
              {/* <img src={familySafty} alt='' /> */}
              <div className='featureimageDiv'>
                <img src={smartgsm} alt='' />
              </div>
              <div className='featureTextDiv'>
                <p className='productName'>
                  The Smart GSM Network
                  <span className='pointSlogan'>
                    Do more with your smartphone
                  </span>
                </p>
                <p className='productDescript'>
                  <ul>
                    <li>
                      Improve performance of domestic/professional workers.
                    </li>
                    <li>Save/make money with our location-based services.</li>
                    <li>
                      Setup virtual number and call groups for your hustle
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className='seconLayerDiv'>
              {/* <img src={familySafty} alt='' /> */}
              <div className='featureimageDiv'>
                <img src={edutainment} alt='' />
              </div>
              <div className='featureTextDiv'>
                <p className='productName'>
                  Edutainment | Play Hard
                  <span className='pointSlogan'>Get rewarded just for fun</span>
                </p>
                <p className='productDescript'>
                  <ul>
                    <li>
                      You Think You Know Nigeria? Prove it for N5,000,000
                      give-away.
                    </li>
                    <li>STEM Quiz channel tailored for your child.</li>
                    <li>
                      Netflix/Movie + Social Media data packages to unwind.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
            <div className='seconLayerDiv'>
              {/* <img src={familySafty} alt='' /> */}
              <div className='featureimageDiv'>
                <img src={freedom} alt='' />
              </div>
              <div className='featureTextDiv'>
                <p className='productName'>
                  Freedom to Roam the World
                  <span className='pointSlogan diffStyling'>
                    Get connected anywhere and everywhere you go
                  </span>
                </p>
                <p className='productDescript'>
                  <ul>
                    <li>
                      SIM/eSIM that works anywhere in Nigeria and around the
                      world.
                    </li>
                    <li >
                      Virtual SIM for smartphone, even works with data from
                      other networks.
                    </li>
                    <li>Roam on MTN network, anywhere you go in Nigeria.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitelFeatures;
