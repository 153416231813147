import React from 'react';
import { Circles, Bars, RotatingLines } from 'react-loader-spinner';
import './Loader.css';

export const Loader = () => {
  return (
    <div
      style={{
        // backgroundColor: "#DCE4ED",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Circles
        height="80"
        width="80"
        color="#2E2D4D"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export const SecondLoader = () => {
  return (
    <div
      className="secondLoader"
      style={{
        backgroundColor: '#DCE4ED',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '0.3',
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '10%',
        left: '0',
        right: '0',
        bottom: '0',
      }}
    >
      <Circles
        height="80"
        width="80"
        color="#2E2D4D"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export const BookingLoader = () => {
  return (
    <div
      className="secondLoader"
      style={{
        backgroundColor: '#DCE4ED',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '0.3',
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '10%',
        left: '0',
        right: '0',
        bottom: '0',
      }}
    >
      <Circles
        height="80"
        width="80"
        color="#2E2D4D"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export const ContractCodeLoader = () => {
  return (
    <div
      className="secondLoader"
      style={{
        backgroundColor: '#DCE4ED',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: '0.3',
        position: 'fixed',
        height: '100vh',
        width: '100vw',
        top: '10%',
        left: '0',
        right: '0',
        bottom: '0',
      }}
    >
      <Circles
        height="80"
        width="80"
        color="#2E2D4D"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
};

export const ButtonLoader = () => {
  return (
    <Bars
      height="20"
      width="80"
      color="#fff"
      ariaLabel="bars-loading"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  );
};

export const transactionLoader = () => {
  return (
    <RotatingLines
      visible={true}
      height="96"
      width="96"
      color="grey"
      strokeWidth="5"
      animationDuration="0.75"
      ariaLabel="rotating-lines-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );
};

// export default Loader
