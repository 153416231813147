
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import './UserQuickTopUp.css'
import { vitelWirelessAxios } from '../../../../utility/axios';
import { TbRecharging } from 'react-icons/tb';
import { FaPhone } from 'react-icons/fa6';

const UserQuickTopUp = () => {
  const [subscriberInfo, setSubscriberInfo] = useState({});
  const [allowRecharge, setAllowRecharge] = useState(false);
  const [airTimeError, setAirtimeErro] = useState(false);
  const navigator = useNavigate();
     const subscribInfoRaw = JSON.parse(localStorage.getItem('subscriberInformation'));
    //  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);

  // const [airTimeTopUpObj, setAirTimeTopUpObj] = useState()

  const formik = useFormik({
    initialValues: {
      MSISDN: subscribInfoRaw.phoneDetails[0].MSISDN,
      amount: '',
    },
    validationSchema: Yup.object({
      MSISDN: Yup.string()
        .matches(/^[0-9]+$/, 'MSISDN must contain only numbers')
        .required('MSISDN is required'),
      amount: Yup.string()
        .matches(/^[0-9]+$/, 'amount must contain only numbers')
        .required('amount is required'),
    }),

    onSubmit: async values => {
      const formattedMSISDN = values.MSISDN.replace(/^0/, '234');
      const MSISDN = {
        MSISDN: formattedMSISDN,
      };
      await vitelWirelessAxios
        .post('/generals/getSubscriberData', MSISDN)
        .then(res => {
          console.log('res', res);
          if (res.data.message == 'Customer MSISDN Found') {
            setSubscriberInfo(res.data.customerData);
            setAllowRecharge(true);
            handleTopUpCredit(res.data.customerData);
            console.log('res', res);
          } else if (res.data.message !== 'Customer MSISDN Found') {
            setAirtimeErro(true);
            console.log('fail to load');
          } else {
            // notifySuccess(res, values);
          }
        })
        .catch(err => console.log(' Login unsuccessfull', err));

      // setLoader(false)
    },
  });

  const handleTopUpCredit = subscriberInfo => {
    navigator('/air-time-top-up', {
      state: {
        amount: formik.values.amount,
        MSISDN: formik.values.MSISDN,
        subscriberInfo: subscriberInfo,
      },
    });
  };
  return ( 
    <div className="parentSubquicktopup">    
      <div className="subuserQuickTopup">  
        <form className="parentQuicktop" onSubmit={formik.handleSubmit}>
          {airTimeError ? (
            <span className="inValidNumError">
              the number you entered is not a valid vitel number please check the number
            </span>
          ) : null}
          <div className="adjustedErroDivUser">
            <div className="topupTitleUser">Quick Top Up</div>

            <div className="numberInputuser">
              <label htmlFor="">Vitel Number</label>
              <input
              readOnly
                type="text"
                placeholder="Enter Vitel number"
                value={formik.values.MSISDN}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="MSISDN"
                id="MSISDN"
              />

              {formik.touched.MSISDN && formik.errors.MSISDN ? <p className="error">{formik.errors.MSISDN}</p> : null}
            </div>
            <div className="numberInputuser">
              <label htmlFor="">Amount</label>
              <input
                type="text"
                placeholder="Enter amount"
                value={formik.values.amount.trimStart()}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="amount"
                id="amount"
              />
              {formik.touched.amount && formik.errors.amount ? <p className="error">{formik.errors.amount}</p> : null}
            </div>
            <div className="userRechargeNowBtnDiv">
              <button className="getUserQuicktopBtn" type="submit">
                <TbRecharging className="rechargIcon" />
                Recharge Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserQuickTopUp;
