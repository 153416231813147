
import "./layout.css"

const HomeNavComponent = (()=>{


    return(

        <>

        <div className="need-help-div">
                  <div className="container need-help-div">
                 <div>Need help! call: 070-1701-1111</div>  
                 <div className="mark-div rmmark"></div>  
                 <div> WhatsApp: 07120000001</div>  
                 <div className="mark-div"></div>  
                 <div> Chat Online</div>  
                 <div className="mark-div"></div> 
                 <div> Email: customer.love@vitelwireless.com</div>  
                 </div> 
            </div>
        
        </>
    )
})

export default HomeNavComponent