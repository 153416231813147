import React from 'react'
import TransactionTable from '../DefaultViewComponent/TransactionTable'

const TransactionHistory = () => {
  return (
    <div>
      <TransactionTable/>
    </div>
  )
}

export default TransactionHistory