import { CiInstagram } from "react-icons/ci";
import "./layout.css";
import { FaFacebookSquare, FaQuestionCircle, FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Footer = (()=>{
    const navigate = useNavigate();


    const navigateFaq = (()=>{
        navigate('/faq')
    })



    return(

        <>
     <section id="footer">
  <div className="main-footer">
    <div className="logoinfo" data-aos = "fade-up">
      <h4 onClick={navigateFaq}> <FaQuestionCircle  className="askIcon" /> Frequently Asked Questions</h4>
     

      <div className="contact-details">
           <p>Contact Us </p>
          
        <li>
          <div className="fa fa-phone"></div><a href="tel:+919326048690">070-701-1111</a></li>
        <li>
          <div className="fa fa-envelope"></div><a href="mailto:yourmail@gmail.com">ourmail@gmail.com</a></li>
        
    </div>
  </div>
  <div className="com " data-aos="fade-up">
    
    <ul>
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#"> Terms of Service</a></li>
      <li><a href="#">Terms of Use</a></li>
      <li><a href="#">Accessibility</a></li>
      <li><a href="#"> Device Return</a></li>
    </ul>
  </div>
  <div className="info" data-aos="fade-up">
    <h1>We are Available on</h1>
    <div className="sociallogos">
      <div className="logobox">
        <a href="#" className="fa fa-instagram"> <CiInstagram  />  </a>
        <a href="#" className="fa fa-facebook"> <FaFacebookSquare /></a>
        <a href="#" className="fa fa-linkedin"> <FaXTwitter /></a>
         <a href="#" className="fa fa-youtube-play"><FaTiktok /> </a>
      </div>
    </div>
  </div>
  </div>
<footer>Vitel, Vitel Mobile, and Vitel Wireless are
   registered trademarks of Vitel Wireless Limited. ©2024</footer>
</section>
        
        
        </>
    )
})

export default Footer