
import CorporateProductForm from "../CoperateProduct/corperateProductForm"
import "./formPage.css"

const FormPage = (()=>{


    return(

      <>


        <div className="control_Set_formUp ">

       

        </div>
        <div className="corpForDiv">
        <CorporateProductForm/>
        </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        
        </>
    )
})

export default FormPage