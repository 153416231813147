import React from 'react'
import VitelFeatures from '../../VitelFeatures/VitelFeatures';
import Faq from '../../faq';
import dataBana from "../../../images/AIR2.png";
import { GrNext } from "react-icons/gr";

const SocialMediaBundlesPage = () => {
  return (
    <div>
      <div className='dataSectionHero'>
        <div className='dataPlanText'>
          <p className='dataPlanTextParagragh'>
            <h3>
              Social Power <br /> Pack...
            </h3>
            The Vitel Wireless Social Power Pack is a specialized data bundle
            designed for social media enthusiasts. Enjoy seamless access to your
            favorite apps, including Facebook, WhatsApp (excluding voice & video
            calls), YouTube, Instagram, and TikTok, all at unbeatable rates.
          </p>
        </div>
      </div>
      <div className='mainSectionDataPlan'>
        <div className='dataPlanCatDiv'>
          <div className='datatypeDiv activeDatatypeDiv'>Hourly Bundles</div>
          <div className='datatypeDiv'>Daily plan</div>
          <div className='datatypeDiv'>2-days plan</div>
          <div className='datatypeDiv'>5-days plan</div>
          <div className='datatypeDiv'>weekly plan</div>
          <div className='datatypeDiv'>monthly plan</div>
          <div className='datatypeDiv'>2-month plan</div>
          <div className='datatypeDiv'>3-month plan</div>
          <div className='datatypeDiv'>6-month plan</div>
          <div className='datatypeDiv'>yearly plan</div>
        </div>
        <div className='mainDataPlanCard'>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
        </div>
      </div>
      <VitelFeatures />
      <Faq />
    </div>
  );
}

export default SocialMediaBundlesPage