import React, { useEffect, useState } from 'react';
import './PartnerForm.css';
import { GrSend } from 'react-icons/gr';
import { vitelWirelessAxios } from '../../../utility/axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ButtonLoader } from '../../../utility/Loader';
import { MdCancelScheduleSend } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { CiHome } from 'react-icons/ci';
import { GiCheckMark } from 'react-icons/gi';

const PartnerForm = () => {
  const [allStateLocalGov, setAllStateLocalGov] = useState();
  const [selectedStateLGA, setSelectedStateLGA] = useState();
  const [showSuccessmsg, setShowSuccessmsg] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

      const scrolltop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
      useEffect(() => {
        document.title = 'Vitel Wireless | become a partner form';
        scrolltop();
      }, []);

  useEffect(() => {
    const getAllState = async () => {
      const [allState, allLocalGovt] = await Promise.all([
        vitelWirelessAxios.get(`generals/states`),
        vitelWirelessAxios.get(`generals/local_govt`),
      ]);

      const combinestateLocal = {
        allState: allState.data,
        allLocalGovt: allLocalGovt.data,
      };

      setAllStateLocalGov(combinestateLocal);
    };
    getAllState();
  }, []);

  const handleStateChange = event => {
    const stateLocalGovt =
      allStateLocalGov.allLocalGovt && allStateLocalGov.allLocalGovt?.filter(LGA => LGA.stateId === event.target.value);
    setSelectedStateLGA(stateLocalGovt);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      companyName: '',
      state: '',
      lga: '',
      streetNumber: '',
      createdBy: '',
      sourceType: 'web',
      username: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required'),
      lastName: Yup.string().required('Last Name is required'),
      emailAddress: Yup.string().email('Invalid email format').required('Email Address is required'),
      phoneNumber: Yup.string().required('Phone Number is required'),
      companyName: Yup.string().required('Company Name is required'),
      state: Yup.string().required('State is required'),
      lga: Yup.string().required('LGA is required'),
      streetNumber: Yup.string().required('Street Number is required'),
      // createdBy: Yup.string().required('Created By is required'),
      // username: Yup.string()
      //   .oneOf([Yup.ref('emailAddress')], 'Username must be the same as Email Address')
      //   .required('Username is required'),
    }),
    onSubmit: async values => {
      setShowLoader(true);
      await vitelWirelessAxios
        .post('generals/createPartner', values)
        .then(res => {
          // console.log('responds', res);
          setShowLoader(false);
          if (res.data.success === true) {
            // let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
            // myModal.show();
            // setShowSuccessmsg(true);
            setShowSuccess(true);
          } else {
            let myModal = new window.bootstrap.Modal(document.getElementById('successModal'));
            myModal.show();
            setShowSuccessmsg(false);
            // console.log('not success');
          }
        })
        .catch(err => {
          console.log('network error', err);
        });
    },
  });

  return (
    <div className="parentPartnerForm">
      <div className="partnerFormdivmain">
        <div className="partnerFormdivimg">
          <img
            src="https://img.freepik.com/premium-vector/office-paper-document-with-folder-flat-design_798171-579.jpg"
            alt=""
          />
        </div>

        <div className="partnerFormdiv">
          {showSuccess ? (
            <div className="parentpartnerInputdiv">
              <div className="successMessage">
                <p>
                  you have <span style={{ color: 'green', fontSize: '1.2em', fontWeight: 'bolder' }}>successfully</span>{' '}
                  sent a request to become a partner with vitel Wireless; we will contact you shortly.
                </p>{' '}
                <span className="iconSpan">
                  <GiCheckMark />
                </span>
              </div>
              <h3>Below are your information</h3>
              <div className="inputOnlyDiv">
                <div className="partnerInputdivshare">
                  <div className="partnerInputdiv">
                    <label htmlFor="firstName">First Name</label>
                    <div className="formDetails">{formik.values.firstName}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="lastName">Last Name</label>
                    <div className="formDetails">{formik.values.lastName}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="emailAddress">Email Address</label>
                    <div className="formDetails">{formik.values.emailAddress}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <div className="formDetails">{formik.values.phoneNumber}</div>
                  </div>
                </div>

                <div className="partnerInputdivshare">
                  <div className="partnerInputdiv">
                    <label htmlFor="username">User Name</label>
                    <div className="formDetails">{formik.values.username}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="companyName">Company Name</label>
                    <div className="formDetails">{formik.values.companyName}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="state">State</label>
                    <div className="formDetails">{formik.values.state}</div>
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="lga">LGA</label>
                    <div className="formDetails">{formik.values.lga}</div>
                  </div>
                </div>
              </div>
              <div className="partnerInputdiv" style={{ marginTop: '-1%' }}>
                <label htmlFor="streetNumber">Street Address</label>
                <div className="formDetails">{formik.values.streetNumber}</div>
              </div>
              <div className="partnerbtndiv">
                <Link to="/become-vitel-partner" className="backpartnerbtn">
                  <CiHome /> Back Home
                </Link>
              </div>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} className="parentpartnerInputdiv">
              <h3>please fill the form below</h3>
              <div className="inputOnlyDiv">
                <div className="partnerInputdivshare">
                  <div className="partnerInputdiv">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('createdBy', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      name="firstName"
                      id="firstName"
                      value={formik.values.firstName}
                    />
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <p className="error">{formik.errors.firstName}</p>
                    ) : null}
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="lastName"
                      id="lastName"
                      value={formik.values.lastName}
                    />
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <p className="error">{formik.errors.lastName}</p>
                    ) : null}
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="emailAddress">Email Address</label>
                    <input
                      type="text"
                      onChange={e => {
                        formik.handleChange(e);
                        formik.setFieldValue('username', e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      name="emailAddress"
                      id="emailAddress"
                      value={formik.values.emailAddress}
                    />
                    {formik.touched.emailAddress && formik.errors.emailAddress ? (
                      <p className="error">{formik.errors.emailAddress}</p>
                    ) : null}
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="phoneNumber">Phone Number</label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="phoneNumber"
                      id="phoneNumber"
                      value={formik.values.phoneNumber}
                    />
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                      <p className="error">{formik.errors.phoneNumber}</p>
                    ) : null}
                  </div>
                </div>

                <div className="partnerInputdivshare">
                  <div className="partnerInputdiv">
                    <label htmlFor="username">User Name</label>
                    <input type="text" name="username" id="username" value={formik.values.username} readOnly />
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="companyName">Company Name</label>
                    <input
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="companyName"
                      id="companyName"
                      value={formik.values.companyName}
                    />
                    {formik.touched.companyName && formik.errors.companyName ? (
                      <p className="error">{formik.errors.companyName}</p>
                    ) : null}
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="state">State</label>
                    <select
                      onChange={e => {
                        handleStateChange(e);
                        formik.handleChange(e);
                      }}
                      name="state"
                      id="state"
                      value={formik.values.state}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">select state</option>
                      {allStateLocalGov &&
                        allStateLocalGov.allState.map((state, index) => (
                          <option value={state.stateId} key={index}>
                            {state.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.state && formik.errors.state ? (
                      <p className="error">{formik.errors.state}</p>
                    ) : null}
                  </div>
                  <div className="partnerInputdiv">
                    <label htmlFor="lga">LGA</label>
                    <select
                      name="lga"
                      id="lga"
                      value={formik.values.lga}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">select LGA</option>
                      {selectedStateLGA &&
                        selectedStateLGA.map((LGA, index) => (
                          <option value={LGA.lgaId} key={index}>
                            {LGA.name}
                          </option>
                        ))}
                    </select>
                    {formik.touched.lga && formik.errors.lga ? <p className="error">{formik.errors.lga}</p> : null}
                  </div>
                </div>
              </div>
              <div className="partnerInputdiv" style={{ marginTop: '-1%' }}>
                <label htmlFor="streetNumber">Street Address</label>
                <textarea
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="streetNumber"
                  id="streetNumber"
                  value={formik.values.streetNumber}
                />
                {formik.touched.streetNumber && formik.errors.streetNumber ? (
                  <p className="error">{formik.errors.streetNumber}</p>
                ) : null}
              </div>

              <div className="partnerbtndiv">
                <Link to="/become-vitel-partner" className="backpartnerbtn">
                  <MdCancelScheduleSend /> cancel request
                </Link>
                {showLoader ? (
                  <button className="sendRequestBtn">
                    <ButtonLoader />
                  </button>
                ) : (
                  <button type="submit" className="sendRequestBtn">
                    <GrSend />
                    send request
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="successModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Request status
              </h1>
            </div>
            {showSuccessmsg ? (
              <div className="modal-body">
                you have successfully sent a request to become a partner with vitel Wireless, we will contact you
                shortly
              </div>
            ) : (
              <div className="modal-body">Oops! It looks like a partner with this information already exists.</div>
            )}

            <div className="modal-footer">
              <button type="button" className="closeModal" data-bs-dismiss="modal" id="modalCloseButton">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
