import React, { useEffect, useState } from "react";
import "./ProductsCart.css";
import { AiFillDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  clearCart,
  clearCartProduct,
  increase,
  removeProduct,
  decrease,
} from "../../redux/productCounter";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import useFectch from "../../utility/getFunction";
import { orderLocationId, orderMenuId } from "../../utility/axios";

const ProductsCart = ({ productInCart }) => {
  // console.log("productInCartparent", productInCart);
  const navigation = useNavigate()
  const dispatch = useDispatch();
  const totalPrice = productInCart.reduce((acc, item) => {
    return acc + Number(item.pricePerItem) * item.quantity;
  }, 0);

  const calculateVAT = (pricePerItem, quantity, addVat) => {
    if (addVat === "Yes") {
      return (pricePerItem * quantity * 7.5) / 100;
    }
    return 0;
  };

  const [checkQuantity, setCheckQuantity] = useState("")

  const calculateItemTotalPrice = (pricePerItem, quantity) => {
    return pricePerItem * quantity;
  };

  const calculateGrandTotal = () => {
    return productInCart.reduce((total, item) => {
      return (
        total +
        calculateItemTotalPrice(item.pricePerItem, item.quantity) +
        calculateVAT(item.pricePerItem, item.quantity, item.addVat)
      );
    }, 0);
  };


const subscribersData = JSON.parse(localStorage.getItem("subscriberInformation"));

  const handleProceedDelivery = () => {
    if(subscribersData){
      navigation('/delivery-pickup')
   }else{
     navigation('/subscriber-login')
   }
  }


  const {
    data: productsData,
    isPending: productsIsPending,
    error: productsError,
  } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  const matchingProducts = productsData.products && productsData.products.filter(product => {
    return productInCart.some(image => image.comboId === product.comboId);
  });

 
  useEffect(()=>{
  },[checkQuantity])
 
 
 const handleCheckNumber = ((details)=>{
   const matchData = matchingProducts &&  matchingProducts.filter((data)=>(
    details.comboId === data.comboId 
 ))
 setCheckQuantity( matchData && matchData.map(item => 
  item.fields[0]?.fieldItems[0]?.currentQuantity 
))
  dispatch(increase(details.productId))
  
 })


 
 const numChecker = parseInt(checkQuantity, 10)

 console.log("log numChecker", numChecker)
 


  return (
    <div className='parentCartDiv'>
      <div style={{ width: "100%" }}>
        <div className='card  '>
          <div className='card-header bg-transparent border-success'>
            Selected Product Details
          </div>

          {productInCart &&
            productInCart.map((details, i) => (
              <div className='card-body' key={i}>
                <h5 className='card-title'>{details.productName}</h5>
                <p className='card-text'>
                  <ul className='list-group'>
                    <li className='list-group-item d-flex justify-content-between align-items-center'>
                      Quantity
                      <span className=''>{details.quantity}</span>
                    </li>
                    {details.categoryName ===
                    "Premium/Value Added Services" ? null : (
                      <li className='list-group-item d-flex justify-content-between align-items-center'>
                        Adjust qty
                        <span className='increaseAndDecreass'>
                          <button
                            className='tractminusCart'
                            onClick={() =>
                              dispatch(decrease(details.productId))
                            }
                            disabled={details.quantity === 1}
                          >
                            <i>
                              <AiOutlineMinus size={15} />
                            </i>
                          </button>
                          <div className='incrementQtyNum'>
                            {" "}
                            <span> {details.quantity} </span>
                          </div>
                          <button
                          disabled = {numChecker <= details.quantity}
                            className='trackplusCart'
                            onClick={() =>handleCheckNumber(details)}
                          >
                            {" "}
                            <i>
                              <BsPlus size={15} />
                            </i>
                          </button>
                        </span>
                      </li>
                    )}

                    <li className='list-group-item d-flex justify-content-between align-items-center de'>
                      Remove Product
                      <span
                        className=''
                        onClick={() =>
                          dispatch(removeProduct(details.productId))
                        }
                      >
                        <AiFillDelete className='deleteProduct' />
                      </span>
                    </li>

                    <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "0.9em" }}
                    >
                      Amount
                      <span className=''>
                        &#8358;{" "}
                        {parseInt(details.pricePerItem).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </span>
                    </li>
                    {details.quantity < 2 ? null : (
                      <li
                        className='list-group-item d-flex justify-content-between align-items-center'
                        style={{ fontSize: "0.9em" }}
                      >
                        Item Subtotal
                        <span className=''>
                          &#8358;{" "}
                          {parseInt(
                            calculateItemTotalPrice(
                              details.pricePerItem,
                              details.quantity
                            )
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </li>
                    )}
                      <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "0.8em" }}>
                      TAX (7.5%)
                      <span className=''>
                        <span className=''>
                          &#8358;{" "}
                          {calculateVAT(
                            details.pricePerItem,
                            details.quantity,
                            details.addVat
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </span>
                    </li>
                    <li
                      className='list-group-item d-flex justify-content-between align-items-center'
                      style={{ fontSize: "1em", borderTop: "2px solid gray" }}
                    >
                      Total
                      <span className=''>
                        &#8358;{" "}
                        {(
                          parseInt(
                            calculateItemTotalPrice(
                              details.pricePerItem,
                              details.quantity
                            )
                          ) +
                          calculateVAT(
                            details.pricePerItem,
                            details.quantity,
                            details.addVat
                          )
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </li>
                  </ul>
                </p>
                <div className='card-header bg-transparent border-success'></div>
              </div>
            ))}

          {/* <div className='card-header bg-transparent border-success'>
            <div className='tax-container'>
              <div className='list-group-item d-flex justify-content-between align-items-center fw-lighter fs-6 text'>
                <span>Tax 7.5%</span>
                <div className='taxAmount'>
                  <span className='taxindiviAmount'>
                    ₦ {parseInt(2000).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div> */}

          <div className='card-body '>
            <p className='card-text'>
              <ul className='list-group'>
                <li
                  className='list-group-item d-flex justify-content-between align-items-center'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  Ground Total
                  <span className='' style={{ fontSize: "1.5em" }}>
                    {" "}
                    &#8358;{" "}
                    {parseInt(calculateGrandTotal()).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </li>
              </ul>
            </p>
          </div>
          <div
            className='card-footer bg-transparent '
            style={{ border: "1px solid #2BBCF1" }}
          >
            <button
              disabled={productInCart.length === 0 ? true : false}
              className='shadow-none col-md-12  btn btn-primary'
              onClick={handleProceedDelivery}
            >
              Proceed to checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsCart;
