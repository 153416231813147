import { FaListUl, FaRegHeart, FaHeart, FaSearch } from 'react-icons/fa';
import './e-commerce.css';
import { GiShoppingCart } from 'react-icons/gi';
import img1 from '../../images/wear/camera.jpg';
import img2 from '../../images/wear/watch2.jpg';
import img3 from '../../images/wear/watch.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFectch from '../../utility/getFunction';
import SearchBar from './searchBar';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartProduct } from '../../redux/productCounter';
import { Alert } from 'react-bootstrap';
// import { addToCartProduct } from "../../redux/counter";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoIosReturnLeft } from 'react-icons/io';
import Skeleton from '../../utility/Skeleton';
import { orderLocationId, orderMenuId, vitelWirelessAxios } from '../../utility/axios';

const Ecommerce = () => {
  const [allCategories, setAllCategories] = useState(true);
  const [subCatName, setSubCatName] = useState('');
  const [showDropDown, setShowDropDown] = useState(true);
  const [item, setItem] = useState('');
  const [showAdded, setShowAdded] = useState(false);
  const [favouritItemArr, setFavouritItemArr] = useState([]);
  // const [data, setData] = useState("");
  // const [productsIsPending, setProductsIsPending] = useState(true)
  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));
  const isLoggedIn = localStorage.getItem('isLoggedIn') === 'true';

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    navigate('/e-commerce');
  };
  const handleShow = () => setShow(true);
  const [showCategoryFilter, setShowCategoryFilter] = useState('');
  const [showCat, setShowCat] = useState('');

  const totalCount = useSelector(state => {
    return state.cartProduct.productItems.length;
  });

  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    document.title = 'Vitel Wireless | e-commerce';
    scrolltop();
  }, []);

  const navigateShoppingCart = () => {
    navigate('/shopping-cart');
  };

  const handleAllSubCat = () => {
    setAllCategories(true);
  };

  // const handleDropDown = (()=>{
  //   setShowDropDown(!showDropDown)
  // })
  const [showData, setShowData] = useState('');

  const navigate = useNavigate();

  const navigateDetailPage = () => {
    navigate('/product-details');
  };

  const navigateCart = pro => {
    // console.log("itemCart", pro);
    navigate('/shopping-cart');
  };


     const [data, setData] = useState([]);
    const [productsIsPending, setProductsIsPending] = useState(true);
    const [error, setError] = useState(null);
  
  useEffect(() => {
    setProductsIsPending(true)
      vitelWirelessAxios.get(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`)
        .then(response => {
  
          if (!response.statusText === "OK") {
            throw Error("Could not fetch data")
          }else{
            setProductsIsPending(false)
          }
          setProductsIsPending(false)
          setData(response.data)
  
          setError(null)
          // console.log("data>>>>>>>>>",data)

        })
        .catch(error => {
          setError(error.message)
          setProductsIsPending(true)
          console.log(error)
        })
    }, []);




 

  // const {
  //   data,
  //   isPending: productsIsPending,
  //   error: productsError,
  // } = useFectch(`/products/menus/${orderMenuId}?locationId=${orderLocationId}`);

  const dispatch = useDispatch();

// useEffect(()=>{

//   const getVitelIoTProduct = async () => {
    
//     const IoTProductDetails = await vitelWirelessAxios.get(
//      `/products/menus/${orderMenuId}?locationId=${orderLocationId}`
//     )
//     setProductsIsPending(false)
//     setData(IoTProductDetails.data)
//   }
//   getVitelIoTProduct() 
// },[])

  

  const subCategoryImages =
    data.products&&
    data.products
      .filter(product => product.productDetailImages && product.productDetailImages.length > 0)
      .flatMap(product => product.productDetailImages);

  // const handleSubCatName = (product) => {
  //     setSubCatName(product.productCategory)
  //     console.log("(major", subCatName);
  //     setAllCategories(false)
  //     setShowDropDown(!showDropDown)

  // }
  console.log('logggggggAgainsubCategoryImages>>>>', subCategoryImages);

  const filterCategory =
    data.products &&
    data.products.filter(product => product.categoryName != 'Data' && product.categoryName != 'Credit Vouchers');

  const filteredProducts =
    subCategoryImages &&
    subCategoryImages.filter(product => product.categoryName != 'Data' && product.categoryName != 'Credit Vouchers')
 
 console.log('itemCart>>>>>>>>>>>>', data);

  console.log('loggggggg', filteredProducts);

  const subCategoryImagesDisplay =
    data.products &&
    data.products
      .filter(product => subCatName === product.productCategory)
      .flatMap(product => product.subCategoryImages);

  const handleDataProduct = pro => {
    dispatch(addToCartProduct(pro));
    setShowAdded(true);
    setItem(pro);
    scrolltop();
    setTimeout(() => {
      setShowAdded(false);
    }, 3000);
  };
  const [checkQuantity, setCheckQuantity] = useState("")

  const symbol = Intl.NumberFormat('en-US', {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    currency: 'NGN',
  })
    .formatToParts(0)
    .filter(part => part.type === 'currency')
    .map(part => part.value)
    .join('');
  //

  const location = useLocation();
  const { product } = location.state || {}; // Safeguard if state is undefined

  const { categories } = location.state || {}; // Safeguard if state is undefined

  console.log('Test the categories', categories);

  const { productItems } = useSelector(state => state.cartProduct);

  const navigateDeliveryPickup = () => {
    navigate('/delivery-pickup');
  };
  const itemsCount = productItems.length;

  useEffect(() => {
    setShowCategoryFilter(categories);
    if (showCategoryFilter) {
      setShowCat(true);
    } else {
      setShowCat(false);
    }
  }, [showCategoryFilter]);

  const totalPrice = productItems.reduce((acc, item) => {
    return acc + Number(item.pricePerItem) * item.quantity;
  }, 0);

  const formatedAmont = totalPrice.toLocaleString();

  const handleNavigateDetails = pro => {
    const encodedProductName = encodeURIComponent(pro.productName);
    navigate(`/product-details/id=${pro.productId}/name=${encodedProductName}`, {
      state: { product: pro },
    });
  };



  const navigateHome = () => {
    navigate('/');
  };

  const navigateEcommerce = () => {
    navigate('/e-commerce');
  };

  const navigateCartPage = () => {
    navigate('/shopping-cart');
  };


  useEffect(() => {
    const fetchWishList = async () => {
      const getSubscriberWishList = await vitelWirelessAxios.get(
        `subscribers/getProductWishlists/${subscribersData?.customerId}`
      );

      console.log('getSubscriberWishList', getSubscriberWishList.data);
      setFavouritItemArr(getSubscriberWishList.data); // Update state to trigger re-render
    };

    fetchWishList();
  }, [subscribersData?.customerId]);

  const handleAddFavourit = async (favItemDetails, newItem) => {
    const userInfor = {
      productId: favItemDetails.productId,
      customerId: subscribersData?.customerId,
      type: newItem,
    };

    await vitelWirelessAxios.post('subscribers/createProductWishlist', userInfor);
    const getSubscriberWishList = await vitelWirelessAxios.get(
      `subscribers/getProductWishlists/${subscribersData?.customerId}`
    );

    setFavouritItemArr(getSubscriberWishList.data); 
    console.log('Item added to favourites:', getSubscriberWishList.data);
  };


  const handleProceedDelivery = () => {
    if (subscribersData) {
      navigate('/delivery-pickup');
    } else {
      navigate('/subscriber-login');
    }
  };


  // const matchCategories = data.products && data.products.subCategoryImages((product)=>{
  //     return  product.subCategoryImages.some(image => image.comboId === product.comboId);
  // })



  const matchingProducts = data.products && data.products.filter(product => {
    return filteredProducts.some(image => image.comboId === product.comboId);
  });


  const currentQuantity = matchingProducts?.fields?.[0]?.fieldItems?.[0]?.currentQuantity || 0;
 
  // setCheckQuantity()


const matchFied = matchingProducts?.fields

   console.log("data>>>>>>>>>",filteredProducts)



  // const numChecker = parseInt(checkQuantity[0], 10)

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="proDisplayforcart">Product successfully added to your cart</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="imgMOdalDiv">
            <div className="imtDiv">
              <div>
                {' '}
                <img className="imgMOdal" src={item?.displayImage} />{' '}
              </div>
            </div>

            <div>
              {' '}
              <span className="itName">{item?.productName} </span> has been added to your cart
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <IoIosReturnLeft /> Continue shopping
          </Button>
          <Button className="aCartMpro" onClick={navigateShoppingCart}>
            <GiShoppingCart /> Check cart
          </Button>
        </Modal.Footer>
      </Modal>

      <div>
        <SearchBar />

        <div className="bredMove">
          <nav className="breadcrumbs">
            <a className="breadcrumbs__item" onClick={navigateHome}>
              Home
            </a>
            <a className="breadcrumbs__item  is-active">All Products</a>
            {/* <a className="breadcrumbs__item  is-active">Shopping Cart</a>  */}

            {/* <a className="breadcrumbs__item ">Checkout</a>  */}
          </nav>
        </div>

        {showAdded && (
          <div className="imgMOdalDiv">
            <div className="dtailDiv1">
              <div className="dtailDiv1-inside">
                <div>
                  {' '}
                  <img className="imgMOdal" src={item?.displayImage} />{' '}
                </div>
                <div>
                  {' '}
                  <span className="itName">{item?.productName} </span>
                  <br />
                  has been added to your cart
                </div>
              </div>
            </div>

            <div className="dtailDiv2">
              <div className="hmCSubtotal">
                Cart Subtotal: {symbol}
                {formatedAmont}
              </div>
              <div>
                <button className="button-Upper" role="button" onClick={handleProceedDelivery}>
                  Proceed to checkout ({itemsCount} items)
                </button>
              </div>

              <div>
                <button className="button-13 mt-2" role="button" onClick={navigateCartPage}>
                  Go to Cart
                </button>
              </div>
            </div>
          </div>
        )}

        {productsIsPending && <Skeleton />}
        <div className="grid-container">
          {product ? (
            <div className="product-card grid-item">
              {/* <div className="badge">Hot</div> */}
              <div className="product-tumb" onClick={() => handleNavigateDetails(product)}>
                <img className="img-product-tumb" src={product?.displayImage} alt="" />
              </div>
              <div className="product-details">
                <span className="product-catagory">{product?.subCategoryName} </span>
                <h5>
                  <a>{product?.productName.slice(0, 15)}</a>
                </h5>
                <p> {product?.shortDescription.slice(0, 50)} </p>
                <div className="product-bottom-details">
                  <div className="product-price">
                    {symbol}{' '}
                    {parseInt(product?.pricePerItem).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                  </div>
                  <div className="product-links">
                    <a class="bt_addTocart" onClick={() => handleDataProduct(product)}>
                      <i>
                        {' '}
                        add to cart <GiShoppingCart />{' '}
                      </i>{' '}
                    </a>
                    {isLoggedIn ? (
                      favouritItemArr.some(item => item.productId === product.productId) ? (
                        <a onClick={() => handleAddFavourit(product, 'exist')}>
                          <i>
                            <FaHeart />
                          </i>
                        </a>
                      ) : (
                        <a onClick={() => handleAddFavourit(product, 'new')}>
                          <i>
                            <FaRegHeart />
                          </i>
                        </a>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            matchingProducts &&
            matchingProducts.map(pro => (


              pro.productDetailImages && pro.productDetailImages.map((img)=>(

                <div className="product-card grid-item">

                {/* const currentQuantity = pro.fields?.[0]?.fieldItems?.[0]?.currentQuantity || 0; */}
    
                    {/* <div className="badge">Hot</div> */}
                    <div className="product-tumb" onClick={() => handleNavigateDetails(img)}>
                      <img className="img-product-tumb" src={img?.displayImage} alt="" />
                    </div>
                    {pro.fields?.[0]?.fieldItems?.map((fieldItem) => (
                         <div key={fieldItem.fieldItemId}>
                             {fieldItem.currentQuantity === 0 ? (
                               <span className='stockDesing-check'>Out of Stock</span>
                           ) : (
                                 null
                           )}
                           </div>
                            ))} 

                    <div className="product-details">
                      <span className="product-catagory">{img?.subCategoryName} </span>      
                      <h5>
                        <a>{img?.productName.slice(0, 15)}</a>
                      </h5>
                      
                      <p> {img?.shortDescription.slice(0, 50)} </p>
                      <div className="product-bottom-details">
                        <div className="product-price">
                          {symbol}{' '}
                          {parseInt(img?.pricePerItem).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{' '}
                        </div>

                      

                          <div className="product-links">
                          <button class="bt_addTocart" onClick={() => handleDataProduct(img)}
                            disabled={pro.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0}
                            >
                            <i>
                              {' '}
                              add to cart <GiShoppingCart />{' '}
                            </i>{' '}
                          </button>
    
                          {/* { matchingProducts?.fields?.[0]?.fieldItems?.[0]?.currentQuantity === 0 ?(
                                <span className="display-Outofstock">
                                  Out of stock
                                </span>
                              ) : null
                            } */}
    
                          
           
        
    
                          {isLoggedIn ? (
                            favouritItemArr.some(item => item.productId === img.productId) ? (
                              <a onClick={() => handleAddFavourit(img, 'exist')}>
                                <i>
                                  <FaHeart />
                                </i>
                              </a>
                            ) : (
                              <a onClick={() => handleAddFavourit(img, 'new')}>
                                <i>
                                  <FaRegHeart />
                                </i>
                              </a>
                            )
                          ) : null}
                        </div>
                      </div>
                    </div>
    </div>
    
    
              ))
             







            ))
          )}
        </div>

        {/* <div>
      {data.products.map((product, index) => (
        <div key={index}>
          <h2>{product.comboName}</h2>
          <div style={{ display: 'flex', gap: '10px' }}>
            {product.productDetailImages.map((image, imgIndex) => (
              <div key={imgIndex}>
                <img 
                  src={image.displayImage} 
                  alt={image.productName} 
                  style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
                />
                <p>{image.shortDescription}</p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div> */}

        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </>
  );
};

export default Ecommerce;
