import React from 'react';
import { IoMdShare } from 'react-icons/io';
import { GiSaveArrow } from 'react-icons/gi';
import html2pdf from 'html2pdf.js';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../../../images/newLogo.png';

const SubtransactionHistory = ({
  calculateVAT,
  calculateItemTotal,
  subTransactionHistory,
  vatCalculateGrandTotal,
  calculateGrandTotal,
}) => {
  const navigation = useNavigate();
  const generatePDF = () => {
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: 'vitelwirlessreceipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };
    const content = document.getElementById('pdfContent');

    html2pdf().set(options).from(content).save();
  };

  const generateAndSharePDF = async () => {
    const element = document.getElementById('pdfContent'); // The HTML content you want to turn into PDF
    const options = {
      margin: [0.5, 1, 0.5, 1],
      filename: 'vitelwirlessreceipt.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    try {
      // Generate PDF as Blob
      const pdfBlob = await html2pdf().from(element).set(options).outputPdf('blob');

      // Convert Blob to File
      const pdfFile = new File([pdfBlob], 'vitelwirlessreceipt.pdf', {
        type: 'application/pdf',
      });

      // Check if Web Share API is supported
      if (navigation.canShare && navigation.canShare({ files: [pdfFile] })) {
        // Use Web Share API
        await navigation.share({
          title: 'My PDF',
          text: 'Check out this PDF file!',
          files: [pdfFile], // Pass the file for sharing
        });

        console.log('Shared successfully');
      } else {
        // Fallback for unsupported browsers
        alert('Sharing not supported on this browser; the file will be downloaded automatically');
        console.log('Sharing not supported. Downloading the PDF instead.');
        const pdfURL = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfURL;
        link.download = 'vitelwirlessreceipt.pdf';
        link.click(); // Trigger download
        URL.revokeObjectURL(pdfURL); // Cleanup the URL
      }
    } catch (error) {
      console.error('Error generating or sharing the PDF:', error);
    }
  };

  const deliveryFee = 2000;
  const pickupFee = 0;
  return (
    <div className="book_form_room_list " style={{ width: '100%' }}>
      <div id="pdfContent">
        <div className="book_hotel__details2" style={{marginTop: "8%"}}>
          <div className="receiptLogo">
            <img src={logo} alt="" />
          </div>
          <h4>Thank you for shopping with <br/>vitel wireless</h4>
        </div>

        <div className="book_view_more">
          <span className="spanDis">
            <span>MSISDN: </span>
            <span style={{ width: '50%' }}>{subTransactionHistory?.customerData.phoneNumber}</span>{' '}
          </span>
        </div>

        <div className="book_view_more_with_tax">
          <span className="spanDis">
            <span>Name on MSISDN</span>{' '}
            <span style={{ width: '50%' }}>
              {subTransactionHistory?.customerData.lastName} {subTransactionHistory?.customerData.firstName}
            </span>{' '}
          </span>
        </div>
        <div className="watermark"></div>
        <div className="book_view_more_with_tax subTotal">
          <span className="spanDis">
            <span>Order reference number</span> <span style={{ width: '50%' }}>{subTransactionHistory?.orderId}</span>{' '}
          </span>
        </div>
        <div className="book_view_more_with_tax subTotal">
          <span className="spanDis">
            <span>Purchase date</span>{' '}
            <span style={{ width: '50%' }}>
              {moment(subTransactionHistory?.createdDateTime).format('MMMM Do, YYYY, h:mm A')}
            </span>{' '}
          </span>
        </div>
        <div className="orderbreakDownDiv subTotal">
          <table className="table">
            <thead className="table-dark">
              <tr>
                <th scope="col">S/N</th>
                <th scope="col">Product Name</th>
                <th scope="col">Product S/N</th>
                <th scope="col">Unit price</th>
                <th scope="col">Value Code</th>
                <th scope="col">Quantity</th>

                <th scope="col">VAT(7.5%)</th>
                <th scope="col">Total AMount</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {subTransactionHistory?.products?.map((history, index) => (
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td>{history.comboName}</td>
                  <td>N/A</td>
                  <td>
                    ₦
                    {parseInt(history.pricePerItem).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td>N/A</td>
                  <td>{history.quantity}</td>
                  <td>
                    {' '}
                    ₦
                    {parseInt(calculateVAT(history.pricePerItem, history.quantity, history.addVat)).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </td>
                  <td>
                    {' '}
                    ₦{' '}
                    {parseInt(
                      calculateItemTotal(history.pricePerItem, history.quantity, history.addVat)
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                  </td>
                </tr>
              ))}

              <tr style={{ borderTop: '2px solid black', borderBottom: 'none' }}>
                <th style={{ border: 'none' }}>Total</th>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td style={{ border: 'none', fontWeight: 'bold' }}>
                  ₦
                  {parseInt(vatCalculateGrandTotal()).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td style={{ border: 'none', fontWeight: 'bold' }}>
                  ₦
                  {parseInt(calculateGrandTotal()).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr style={{ border: 'none' }}>
                <th style={{ border: 'none' }} colSpan="7">
                  Service Type
                </th>
                <td style={{ border: 'none' }}>{subTransactionHistory?.typeOfService}</td>
              </tr>
              <tr style={{ border: 'none' }}>
                <th style={{ border: 'none' }} colSpan="7">
                  Delivery fee
                </th>
                <td style={{ border: 'none' }}>
                  ₦ {subTransactionHistory?.typeOfService === 'pickup' ? pickupFee : deliveryFee}
                </td>
              </tr>
              <tr style={{ borderTop: '2px solid black' }}>
                <th colSpan="7">Amount Paid</th>
                <td style={{ fontWeight: 'bolder' }}>
                  {' '}
                  ₦{' '}
                  {parseInt(
                    calculateGrandTotal() +
                      (subTransactionHistory?.typeOfService === 'pickup' ? pickupFee : deliveryFee)
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ padding: '15px' }}>
            <h5>purchased product serial numbers</h5>
            <ol>
              {subTransactionHistory &&
                subTransactionHistory?.orderSerialNumbers?.map(serialNumber => <li>{serialNumber.serialNumber}</li>)}
            </ol>
          </div>
        </div>

        {/* <div className="book_view_more_with_tax subTotal deliveryInfo">
          <span>
            {subTransactionHistory?.typeOfService === 'pickup' ? <h5>Your pickup center</h5> : <h5>Your Delivery</h5>}
          </span>
          
          <p className="deliveryText">
            {subTransactionHistory?.typeOfService === 'pickup'
              ? `${subTransactionHistory?.customerData?.area} ${subTransactionHistory?.customerData?.lgaName}, ${subTransactionHistory?.customerData?.stateName}.`
              : subTransactionHistory?.customerData?.deliveryAddress}
          </p>

          <span>
            <h5>Your Purchase is Complete!</h5>
            <p className="deliveryText">
              Thank you for your purchase! Your order has been placed successfully. You will receive a confirmation
              email soon, and your items will be shipped within the next 2-3 business days. We hope you enjoy your
              purchase!
            </p>
          </span>
        </div> */}

        <div className="book_view_more_with_tax subTotal deliveryInfo">
          <span>
            {subTransactionHistory?.typeOfService === 'pickup' ? <h5>Your pickUp center</h5> : <h5>Your Delivery</h5>}
          </span>
          <p className="deliveryText">
            {subTransactionHistory?.typeOfService === 'pickup'
              ? `${subTransactionHistory?.customerData?.area} ${subTransactionHistory?.customerData?.lgaName}, ${subTransactionHistory?.customerData?.stateName}.`
              : `${subTransactionHistory?.customerData?.deliveryAddress}, ${subTransactionHistory?.customerData?.lgaName}, ${subTransactionHistory?.customerData?.stateName}`}
          </p>

          <span>
            <h5>Your Purchase is Complete!</h5>
            <p className="deliveryText">
              Thank you for your purchase! Your order has been placed successfully. You will receive a confirmation
              email soon, and your items will be shipped within the next 2-3 business days. We hope you enjoy your
              purchase!
            </p>
          </span>
        </div>
      </div>
      <div className="backToHome">
        <div className="saveIconDiv" onClick={generatePDF}>
          <span className="shareIconspan">
            <GiSaveArrow className="saveIcon" />
          </span>
          <span>Download Receipt</span>
        </div>
        <div className="shareIconDiv saveIconDiv2" onClick={generateAndSharePDF}>
          <span className="shareIconspan">
            <IoMdShare className="saveIcon" />
          </span>
          <span>Share Receipt</span>
        </div>
      </div>
    </div>
  );
};

export default SubtransactionHistory;
