import React, {useState, useEffect} from 'react'
import { useNavigate, useLocation, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { vitelWirelessAxios } from "../../utility/axios";
import "./PlanPurchase.css"
import { MdToggleOn } from "react-icons/md";
import { CgToggleOn } from "react-icons/cg";
import { RxEnter } from "react-icons/rx";
import { FaLink } from "react-icons/fa6";
import { TbCameraSelfie } from "react-icons/tb";
import dataBana from "../../images/AIRTIMETOPUPP.jpg";


const PlanPurchase = () => {
const [showInputField, setShowInputField] = useState(false)
const [showLinkedNumbers, setShowLinkedNumbers] = useState(false)
const [useMainNumbers, setUseMainNumbers] = useState(false)
const [checkLogin, setCheckLogin] = useState(false)
const [airTimeError, setAirtimeErro] = useState(false);
const { id } = useParams();
const navigation = useNavigate();
const location = useLocation();
// console.log("productId", id);
const { service } = location.state

 useEffect(() => {
   document.title = service
     ? `Vitel Wireless | Plan Purchase Page - ${service.productName}`
     : "Plan Purchase Page";
 }, [service]);

const handleShowInputField = () => {
  setShowInputField(true);
   setUseMainNumbers(false);
   setShowLinkedNumbers(false);
}

const handleShowLinkedNum = () =>{
  setShowLinkedNumbers(true);
  setUseMainNumbers(false);
  setShowInputField(false);
}

const handleUseMainNum = () => {
  setUseMainNumbers(true);
  setShowLinkedNumbers(false);
  setShowInputField(false);
}

const handleLogin = () => {
  setCheckLogin(true);
navigation("/subscriber-login");
}

const handleBuyNow = (subscriberInfo) => {
  navigation("/air-time-top-up", {
    state: {
      amount: formik.values.amount,
      MSISDN: formik.values.MSISDN,
      subscriberInfo: subscriberInfo,
    },
  });
};

const formik = useFormik({
  initialValues: {
    MSISDN: "",
    amount: service.pricePerItem,
  },
  validationSchema: Yup.object({
    MSISDN: Yup.string()
      .matches(/^[0-9]+$/, "MSISDN must contain only numbers")
      .required("MSISDN is required"),
    amount: Yup.string()
      .matches(/^[0-9]+$/, "amount must contain only numbers")
      .required("amount is required"),
  }),

  onSubmit: async (values) => {
    console.log("values ====>>>>", values);
    const MSISDN = {
      MSISDN: values.MSISDN,
    };
    await vitelWirelessAxios
      .post("/generals/getSubscriberData", MSISDN)
      .then((res) => {
        // console.log("res", res);
        if (res.data.message == "Customer MSISDN Found") {
          handleBuyNow(res.data.customerData);
          // console.log("res", res);
        } else if (res.data.message !== "Customer MSISDN Found") {
          setAirtimeErro(true);
          console.log("fail to load");
        } else {
          // notifySuccess(res, values);
        }
      })
      .catch((err) => console.log(" Login unsuccessfull", err));

    // setLoader(false)
  },
});

const handleMSISDNChange = (e) => {
  let value = e.target.value;
  if (value.startsWith("0")) {
    value = "234" + value.slice(1);
  }
  formik.setFieldValue("MSISDN", value);
};

  return (
    <div className='parentActivatePlan'>
      <div className='mainPlanContainer'>
        <div className='planDescriptionDiv'>
          <div className='planDescriptionImg'>
            <img src={dataBana} alt='' />
          </div>
          <div className='dataPlanNameDiv'>
            <div className='mainDataPlanName'>{service?.productName}</div>
            <div className='mainDataPlanDescription'>
              {service.longDescription}
            </div>
            <div className='priceValidityDiv'>
              <div className='planPrice'>
                <span className='priceDiv'>Price</span>{" "}
                <span>₦{parseInt(service.pricePerItem).toLocaleString()}</span>
              </div>
              <div className='planValididty'>
                <span className='validityDiv'>Validity</span>{" "}
                <span>30 days</span>
              </div>
            </div>
          </div>
        </div>
        <div className='planActionDiv'>
          <form className='planActionMainDiv' onSubmit={formik.handleSubmit}>
            <span className='planTittle'>Buy For</span>
            <span className='clickLogin'>
              Login to use your profile{" "}
              <span className='mainClick' onClick={handleLogin}>
                click here
              </span>
            </span>
            <div className='enterNumberDiv'>
              <div className='parentMainEnterDiv'>
                {checkLogin ? (
                  <div
                    className={`mainEnterBtnDiv ${
                      useMainNumbers ? "mainEnterBtnDivActive" : null
                    }`}
                    onClick={handleUseMainNum}
                  >
                    <TbCameraSelfie className='enterIcon' />
                    Myself
                    <br />
                    08139734085
                  </div>
                ) : null}

                <div
                  className={`mainEnterBtnDiv ${
                    showInputField ? "mainEnterBtnDivActive" : null
                  }`}
                  onClick={handleShowInputField}
                >
                  <RxEnter className='enterIcon' />
                  Enter
                  <br /> Number
                </div>

                {checkLogin ? (
                  <div
                    className={`mainEnterBtnDiv ${
                      showLinkedNumbers ? "mainEnterBtnDivActive" : null
                    }`}
                    onClick={handleShowLinkedNum}
                  >
                    <FaLink className='enterIcon' />
                    Linked
                    <br /> Numbers
                  </div>
                ) : null}
              </div>
              {showInputField ? (
                <div className='enterInputDiv'>
                  <label htmlFor=''>Enter Vitel Number</label>
                  <input
                    type='text'
                    placeholder='Enter number 071200000XXX'
                    onChange={handleMSISDNChange}
                  />
                  {formik.touched.MSISDN && formik.errors.MSISDN ? (
                    <p className='error'>{formik.errors.MSISDN}</p>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className='autoRenewalDiv'>
              Auto Renewal{" "}
              <span className='renewalToggle'>
                <MdToggleOn />
                {/* <CgToggleOn/> */}
              </span>{" "}
              (for airtime payment)
            </div>
            {airTimeError ? (
              <span className='inValidNumError'>
                the number you entered is not a valid vitel number
              </span>
            ) : null}
            {showLinkedNumbers ? (
              <div className='linkedNumbers'>
                <ul>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio1'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio2'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio3'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio4'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio5'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio6'
                    />
                  </li>
                  <li>
                    07123099333{" "}
                    <input
                      type='radio'
                      name='phoneNumber'
                      className='custom-radio'
                      id='radio7'
                    />
                  </li>
                </ul>
              </div>
            ) : null}

            <div className='paymentSubtotalDiv'>
              <div className='subTotalDiv'>
                <span className='subTotalTitle'>subTotal</span>
                <span className='subTotalPrice'>
                  ₦{parseInt(service.pricePerItem).toLocaleString()}
                </span>
              </div>
              <div className='buyItemDiv'>
                <button className='planAddTOCart'>Add to cart</button>
                <button
                  className='planBuyNow'
                  // onClick={handleBuyNow}
                  type='submit'
                >
                  Buy Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PlanPurchase