import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import HomeNavComponent from './home-nav-component';
import { useState } from 'react';
import Footer from './footer';
import LearnMoreNav from './learnmore-nav';

const Faq = () => {
  const [hideFaq, sethideFaq] = useState(false);

  const handleHideFaq = () => {
    sethideFaq(!hideFaq);
  };

  const [hide2, setHide2] = useState(false);

  const handlehide2 = () => {
    setHide2(!hide2);
  };

  const [hide3, setHide3] = useState(false);

  const handlehide3 = () => {
    setHide3(!hide3);
  };

  return (
    <>
      {/* <LearnMoreNav/>  */}

      <div className="contain containfaq-title">
        <h1>Frequently Asked Questions</h1>

        <div className="containFAQ">
          <div className="cardfaq">
            <div className="cardfaqli" onClick={handleHideFaq}>
              <div>
                <h4>How much do I pay to get Vitel Wireless? </h4>
              </div>
              {!hideFaq ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hideFaq && (
              <div className="vitelsAnswer">
                {' '}
                Vitel Wireless will be available soon, and the pricing details will be disclosed at that time.
              </div>
            )}
          </div>
          <hr />

          <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide2}>
              <div>
                <h4>How long does it take to get Vitel Wireless? </h4>
              </div>
              {!hide2 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide2 && (
              <div className="vitelsAnswer">
                {' '}
                Vitel Wireless will be available soon. Once you place an order, you can expect immediate delivery. It’s
                that easy, hassle-free and seamless.
              </div>
            )}
          </div>
          <hr />
          <div className="cardfaq">
            <div className="cardfaqli" onClick={handlehide3}>
              <div>
                <h4>Do I need special training to use Vitel Wireless? </h4>
              </div>
              {!hide3 ? (
                <div>
                  {' '}
                  <RiArrowDropDownLine size={30} />{' '}
                </div>
              ) : (
                <div>
                  {' '}
                  <RiArrowDropUpLine size={30} />{' '}
                </div>
              )}
            </div>
            {hide3 && (
              <div className="vitelsAnswer">
                {' '}
                No training is required. Vitel wireless is just like any other network provider in Nigeria. We also
                offer a special family security system to ensure your family's safety and provide efficient data offers.
              </div>
            )}
          </div>
          <hr />
          {/* <button className="custom-btn btn-6"><span>Read More</span></button> */}
        </div>
      </div>

      <br />
    </>
  );
};

export default Faq;
