import React from 'react';
import './MobileSubscriberprofile.css';
import { MdDelete } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlinePhoneLocked } from 'react-icons/md';

const MobileSubscriberProfile = () => {
  const subscribInfoRaw = localStorage.getItem('subscriberInformation');
  const subscribInfoProcessed = JSON.parse(subscribInfoRaw);
  console.log('subscribInfo', JSON.parse(subscribInfoRaw));
  return (
    <div className="mobilesubscriberprofile">
      <div className="profileheaderPart">
        <h2 className="header-title">Profile</h2>
        <div className="profileEditDiv">
          <FaRegEdit className="profileEditIcon" />
        </div>
      </div>

      <div className="userProfileInfo">
        <div className="userDetails">
          <img src="https://i.pinimg.com/474x/0a/a8/58/0aa8581c2cb0aa948d63ce3ddad90c81.jpg" alt="" />
          <h3 className="subscriberName">{subscribInfoProcessed.firstName}</h3>
          <span className="subscriberEmail">{subscribInfoProcessed.emailAddress}</span>
        </div>

        <div className="linkedNumber">
          {subscribInfoProcessed.phoneDetails.map(phoneNum => (
            <div className="numbersDiv">
              <div className="number">
                <div className="numberCover">
                  <MdOutlinePhoneLocked />
                </div>
                <div className="mainNumberDiv">
                  <span className="actualNum">{phoneNum.MSISDN}</span>
                  <span className="activeNum">{phoneNum.KYC === 'No' ? 'Not Active' : 'Active'}</span>
                </div>
              </div>
              <div className="action">:</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileSubscriberProfile;
