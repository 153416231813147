import { Fade } from "react-bootstrap"
import { FaArrowRightLong, FaRegHandPointRight } from "react-icons/fa6";
import "./coperateProduct.css"
import { IoArrowRedo } from "react-icons/io5";
import img1 from "../../images/imgNow12.png";
import img2 from "../../images/product_image3.jpg";
import img3 from "../../images/imgNow2.png";
import img4 from "../../images/img222.png"
import { useForm } from "react-hook-form";
import { vitelWirelessAxios } from "../../utility/axios";
import CorporateProductFormor from "./corperateProductForm";
import CorporateProductForm from "./corperateProductForm";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


let renderCount = 0;

const CoperateProduct = (() => {

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm();
  renderCount++;

  const navigate = useNavigate();


  const handleShowForm = () => {

    navigate("/business-solutions-request")

    // const targetElement = document.getElementById('formSection');
    // if (targetElement) {
    //   targetElement.scrollIntoView({ behavior: 'smooth' });
    // }
  };


  const scrolltop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrolltop();
  }, []);


  return (

    <>
      <div classNameName="Saint">
        <div className="kktest">
          <div className="kktest_insideDiv">
            <div className="kktest_insideDivIN">
              <h2>IT IS TIME FOR AN UPGRADE <br/> 
                 <span className="toNEtwork">  TO A NETWORK
                 THAT CARES MORE ABOUT  YOUR BUSINESS. </span> </h2>
              <button onClick={handleShowForm} className="button-firstDiv" role="button">Submit Request</button>
            </div>

          </div>




        </div>

      </div>

      <div className="pricing_cav" id="pricingdiv">

        <div className="knowusDiscover">
          <div className="down_divMark"></div>
          <div>
            <span className="knowus_test" style={{ color: "#343434" }}>Innovations</span><br />
            <span className="discoverTextFirst">Tailored innovations for your business </span><br />

          </div>
        </div>




        <div className='contain callingDIv centralSaint_div  '>

          <div className="mobile_noneShow">
            <img src={img1} className='side-imageS_sec' />
          </div>

          <div className='dataText-side' >
            <h3 className='cal'>Employee plans & mobile phones</h3>
            <div className='line-textSide'></div>
            <br />

            <div className="card-body_cav">
              <li>Closed User Group (CUG) for up to 50 staffs allows free calls/SMS amongst the staff.</li>
              <li>Free Company Communication App (Android & iOS)</li>
              <li>Discounted mobile plans & phones for staffs</li>

            </div>



          </div>

          <div className="mobile_Show">
            <img src={img1} className='side-imageS_sec' />
          </div>

        </div>

        <div className='contain callingDIv centralSaint_div'>


          <div className='dataText-side' >
            <h3 className='cal'>Supplementary devices</h3>
            <div className='line-textSide'></div>
            <br />

            <div className="card-body_cav">
              <ul>
                Get more support with peripheral devices

                <li>Tablets</li>
                <li>Push-To-Talk walkie talkies</li>
                <li>Logistics Beacons & handhelds Locators</li>
                <li>Internet of Things (IoT)</li>
                <li>Location-Based Services (LBS)</li>
              </ul>
            </div>



          </div>

          <div>
            <img src={img2} className='side-imageS_sec' />
          </div>
        </div>

        <div className='contain callingDIv centralSaint_div'>


          <div className="mobile_noneShow">
            <img src={img3} className='side-imageS_sec' />
          </div>
          <div className='dataText-side' >
            <h3 className='cal'>Rewards for everyone</h3>
            <div className='line-textSide'></div>
            <br />
            <div className="card-body_cav">


              Earn rebates and perks with the best corporate
              telecom packages we have. You earn double
              rewards (you earn points as a team user and as individual user).
            </div>
          </div>
          <div className="mobile_Show">
            <img src={img3} className='side-imageS_sec' />
          </div>

        </div>

        <div className='contain callingDIv centralSaint_div'>



          <div className='dataText-side' >
            <h3 className='cal'>Rated #1 Customer Experience and #1 Innovator for Businesses</h3>
            <div className='line-textSide'></div>
            <br />

            <div className="card-body_cav">



              Do more with a telecom experience like
              never before. Put your
              telecom equipment to use and
              double your hustle.
            </div>
          </div>


          <div>
            <img src={img4} className='side-imageS_sec' />
          </div>


        </div>


        <div className="knowusDiscover">
          <div className="down_divMark"></div>
          <div>
            <span className="knowus_test" style={{ color: "#343434" }}>HOW IT WORKS</span><br />
            <span className="discoverTextFirst">Just 3 easy steps to get your business connected </span><br />

          </div>
        </div>


        <div className="form_centerText">

          <div className="row_doWn">
            <div className="extradiv-cav  ">
              <a href="#"><i className="fa-3x fa fa-desktop"></i></a>
              <h3>Submit request <span style={{ color: "#2BBCF1", cursor: "pointer" }} onClick={handleShowForm} >Here </span>    </h3>
              <p>You will hear back within 2 working days and we’ll assess if we can meet your requirements </p>
            </div>
            <div className="extradiv-cav ">
              <a href="#"><i className="fa-3x fa fa-trophy"></i></a>
              <h3>Place your order</h3>
              <p>Our friendly business consultants will assist you with your purchase, deployment, or queries </p>
            </div>
            <div className="extradiv-cav ">
              <a href="#"><i className="fa-3x fa fa-magic"></i></a>
              <h3>Get your SIM cards</h3>
              <p>Pick them up or have them delivered to your doorstep within 5 working days </p>
            </div>
          </div>



          {/* <div className="row_sec_doWn" id="formSection">  

  <CorporateProductForm/>
  
</div> */}

        </div>





        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />

























        {/* <div className="gen_conT_div">
        <div className="main_conT_div">
          <div className="side_firstFF_text">
            <h2> Employee plans & mobile phones</h2>
            <div className="card-body_cav">
              <li>Closed User Group (CUG) for up to 50 staffs allows free calls/SMS amongst the staff.</li>
              <li>Free Company Communication App (Android & iOS)</li>
              <li>Discounted mobile plans & phones for staffs</li>

            </div>   
          </div>
          <div className="side_firstFF_pic">
               <img className="firstFF_pic" src={img1}/>
          </div>


        </div>

        <div className="main_conT_div">
         
          <div className="side_firstFF_pic">
               <img className="firstFF_pic" src={img2}/>
          </div>
          <div className="side_firstFF_text">
          <h2> Supplementary devices</h2>
            <div className="card-body_cav">
          
                  <li>Earn rebates and perks with the best corporate telecom packages we
                    have. You earn double rewards (you earn points as a team user and as individual user). </li>


           

            </div>   
          </div>

        </div>

        <div className="main_conT_div">
        <div className="side_firstFF_text">
         <h2> Employee plans & mobile phones</h2>
           <div className="card-body_cav">
             <li>Closed User Group (CUG) for up to 50 staffs allows free calls/SMS amongst the staff.</li>
             <li>Free Company Communication App (Android & iOS)</li>
             <li>Discounted mobile plans & phones for staffs</li>

           </div>   
         </div>
         <div className="side_firstFF_pic">
              <img className="firstFF_pic" src={img3}/>
         </div>
        

       </div>

       <div className="main_conT_div">
       
         <div className="side_firstFF_pic">
              <img className="firstFF_pic" src={img4}/>
         </div>
         <div className="side_firstFF_text">
         <h2> Employee plans & mobile phones</h2>
           <div className="card-body_cav">
             <li>Closed User Group (CUG) for up to 50 staffs allows free calls/SMS amongst the staff.</li>
             <li>Free Company Communication App (Android & iOS)</li>
             <li>Discounted mobile plans & phones for staffs</li>

           </div>   
         </div>

       </div>






</div> */}





      </div>






      {/* <div className="knowusDiV">
                        <div className="knowusDiscover">
                            <div className="down_divMark"></div>
                            <div>
                                <span className="knowus_test" style={{ color: "white" }}>GET TO KNOW US</span><br />
                                <span className="discoverTextFirst">How it works </span><br />
                          
                            </div>
                        </div>

                        <div className="general_cards">

                        <div className="header-extradiv">
   
  </div>



                        </div>


                    </div>  */}



</>
  )
})

export default CoperateProduct  