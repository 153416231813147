import React, { useEffect, useState } from 'react';
import { vitelWirelessAxios } from '../../../../utility/axios';
import moment from 'moment';
import './Notifications.css';
import { TbReport } from 'react-icons/tb';
import { FaChevronDown } from 'react-icons/fa';


const Notifications = () => {
   const [subscriberHistory, setSubscriberHistory] = useState([]);
   const [activeButton, setActiveButton] = useState("allnotice");

  const subscribersData = JSON.parse(localStorage.getItem('subscriberInformation'));

  useEffect(() => {
    const getSubscriberHistory = async () => {
      const hasMultipleEntries = subscribersData.phoneDetails.length > 1;

    
      let MSISDN;
    
      if (hasMultipleEntries) {
        const filtered = subscribersData.phoneDetails.filter(msisdn => msisdn.MSISDN === '2347120001199');
        
        MSISDN = filtered.length > 0 ? filtered[0].MSISDN : subscribersData.phoneDetails[0].MSISDN;
      } else {
        MSISDN = subscribersData.phoneDetails[0].MSISDN;
      }
  
      const correctMSISDN = {
        MSISDN: MSISDN,
      };
    
      const [mainSubscriberHistory, topupNotification] = await Promise.all([
        vitelWirelessAxios.get(`/subscribers/getSubscriberOrders/${subscribersData.customerId}`),
        vitelWirelessAxios.post(`/generals/getTotUpHistory`, correctMSISDN)
      ]);
      
      const subscriberHistoryData = mainSubscriberHistory.data;
      const topupNotificationData = topupNotification.data;

      const combinedResults = {
        subscriberHistory: subscriberHistoryData,
        topupNotification: topupNotificationData,
      };

      console.log("topupNotification", combinedResults)
      setSubscriberHistory(combinedResults);
    };
    getSubscriberHistory();
  }, [subscribersData.customerId]);


  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  return (
    <div className="parentReport">  
      <div className='notificationheaderpart'>
          <h2 className="noteheader-title">My Account Notifications</h2>
          <div className='noteTogglebtn'>
            <button  className={activeButton === 'allnotice' ? 'activenotificate' : ''} 
        onClick={() => handleButtonClick('allnotice')}>All notifications</button>
      <button 
        className={activeButton === 'airtime' ? 'activenotificate' : ''} 
        onClick={() => handleButtonClick('airtime')}
      >
        Airtime Topup
      </button>
      <button 
        className={activeButton === 'items' ? 'activenotificate' : ''} 
        onClick={() => handleButtonClick('items')}
      >
        Items Purchase
      </button>
    </div>
        
      </div>
      
      <div className="trackingList">
      {activeButton === "items" ? (
        <>
          {subscriberHistory.subscriberHistory?.map((notification, index) => (
            <div key={index} className="user-name">
              <span className="notedate">
                {moment(notification.createdDateTime).format('YYYY-MM-DD h:mm A')}
              </span>
              <div className="notetitlediv">
                <span className="noteTitle">Transaction Successful</span>
                <span
                  className={`defaultstatus ${
                    notification.orderStatus === 'success'
                      ? 'notestatus'
                      : notification.orderStatus === 'fail'
                      ? 'notestatusfail'
                      : 'defaultstatus'
                  }`}
                >
                  {notification.orderStatus}
                </span>
              </div>
              <div className="notemessagediv">
                <span className="notemessage">
                  Your purchase with the order id ({notification.orderId}) was successfully created.
                </span>
                <FaChevronDown />
              </div>
            </div>
          ))}
        </>
      ) : activeButton === "airtime" ? (
        <>
          {subscriberHistory.topupNotification?.map((notification, index) => (
            <div key={index} className="user-name">
              <span className="notedate">
                {moment(notification.createdDateTime).format('YYYY-MM-DD h:mm A')}
              </span>
              <div className="notetitlediv">
                <span className="noteTitle">Airtime Topup</span>
                <span className="notestatus">Success</span>
              </div>
              <div className="notemessagediv">
                <span className="notemessage">
                  Your airtime topup of {notification.amount} was successful.
                </span>
                <FaChevronDown />
              </div>
            </div>
          ))}
        </>
      ) : (
        // Default view for "All Notifications"
        <>
          {subscriberHistory.subscriberHistory?.map((notification, index) => (
            <div key={index} className="user-name">
              <span className="notedate">
                {moment(notification.createdDateTime).format('YYYY-MM-DD h:mm A')}
              </span>
              <div className="notetitlediv">
                <span className="noteTitle">Transaction Successful</span>
                <span
                  className={`defaultstatus ${
                    notification.orderStatus === 'success'
                      ? 'notestatus'
                      : notification.orderStatus === 'fail'
                      ? 'notestatusfail'
                      : 'defaultstatus'
                  }`}
                >
                  {notification.orderStatus}
                </span>
              </div>
              <div className="notemessagediv">
                <span className="notemessage">
                  Your purchase with the order id ({notification.orderId}) was successfully created.
                </span>
                <FaChevronDown />
              </div>
            </div>
          ))}

{subscriberHistory.topupNotification?.map((notification, index) => (
            <div key={index} className="user-name">
              <span className="notedate">
                {moment(notification.createdDateTime).format('YYYY-MM-DD h:mm A')}
              </span>
              <div className="notetitlediv">
                <span className="noteTitle">Airtime Topup</span>
                <span className="notestatus">Success</span>
              </div>
              <div className="notemessagediv">
                <span className="notemessage">
                  Your airtime topup of {notification.amount} was successful.
                </span>
                <FaChevronDown />
              </div>
            </div>
          ))}
        </>
      )}
       

        
      </div>
    </div>
  );
};

export default Notifications;
