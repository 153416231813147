import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const userIsAuthenticated = JSON.parse(localStorage.getItem('subscriberInformation'));

  if (!userIsAuthenticated) {
    // Redirect to login or any other route if the user is not authenticated
    return <Navigate to="/subscriber-login" />;
  }

  // If authenticated, render the children components (protected routes)
  return children;
};

export default ProtectedRoute;
