import React from 'react'
import "./DataPlans.css"
import dataBana from "../../images/AIR2.png";
import { GrNext } from "react-icons/gr";
import VitelFeatures from '../VitelFeatures/VitelFeatures';
import Faq from '../faq';

const DataPlans = () => {
  return (
    <div className='parentDataPanDiv'>
      <div className='dataSectionHero'>
        <div className='dataPlanText'>
          <p className='dataPlanTextParagragh'>
            <h3>Data Plan...</h3>
            Discover data plans from Vitel Wireless on our cutting-edge 4G+ and
            5G networks. Choose from a wide selection of affordable data bundles
            for your mobile and MiFi devices. You can also activate your
            preferred internet plan by dialing *312#. Experience the excellence
            of Nigeria's leading 5G network.
          </p>
        </div>
      </div>
      <div className='mainSectionDataPlan'>
        <div className='dataPlanCatDiv'>
          <div className='datatypeDiv activeDatatypeDiv'>Hourly Bundles</div>
          <div className='datatypeDiv'>Daily plan</div>
          <div className='datatypeDiv'>2-days plan</div>
          <div className='datatypeDiv'>5-days plan</div>
          <div className='datatypeDiv'>weekly plan</div>
          <div className='datatypeDiv'>monthly plan</div>
          <div className='datatypeDiv'>2-month plan</div>
          <div className='datatypeDiv'>3-month plan</div>
          <div className='datatypeDiv'>6-month plan</div>
          <div className='datatypeDiv'>yearly plan</div>
        </div>
        <div className='mainDataPlanCard'>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
          <div className='parentServiceCard'>
            <div className='upperServiceCard'>
              <div className=' serviceCardImg2'>
                <img src={dataBana} alt='' />
              </div>
              <div className='serviceNameDiv'>
                <span className='categoryname'>category name</span>
                <span className='serviceName'>product name</span>
              </div>
            </div>
            <div className='lowerServiceCard'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
            </div>
            <div className='lowerServiceFooter'>
              <div className='servicePriceDiv'>
                <span className='onlyDiv'>Only</span>
                <span className='servicePriceDiv'>&#8358; 2000</span>
              </div>
              <div className='servicePriceIcon'>
                <GrNext />
              </div>
            </div>
          </div>
        </div>
      </div>
      <VitelFeatures />
      <Faq />
    </div>
  );
}

export default DataPlans