import React, { useState, useEffect } from 'react';
import SideBar from './SidarComponent/SideBar';
import './DashBoard.css';
import Content from './DashBoardContent/ContentComponents/Content';
import SubscriberProfile from './DashBoardContent/SubscriberProfile/SubscriberProfile';


const DashBoard = () => {
  const [activeComponent, setActiveComponent] = useState('dashboard');
  const [activeMenuItem, setActiveMenuItem] = useState('dashboard');
    const scrolltop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

   useEffect(() => {
     document.title = 'Vitel Wireless | subscriber dashboard';
     scrolltop();
   }, []);
  

  const handleMenuItemClick = component => {
    setActiveComponent(component);
    setActiveMenuItem(component);
  };

  return (
    <div className="parentSecuremeDash">
      
      <SideBar  onMenuItemClick={handleMenuItemClick} activeMenuItem={activeMenuItem} />
      <div className="dashboard-content">
        <Content onMenuItemClick={handleMenuItemClick} activeComponent={activeComponent} />
      </div>
      <SubscriberProfile />
    </div>
  );
};

export default DashBoard;
