
import React, { useState, useEffect } from "react";
import { vitelWirelessAxios } from "../../utility/axios";
import { Link, useNavigate } from "react-router-dom";
import "./VitelServicePlan.css"
import bonus from "../../images/dataActive.png";
import { GrNext } from "react-icons/gr";
import noProduct from '../../images/noitemfound.webp'
import dataBana from "../../images/AIRTIMETOPUPP.jpg";
import { SecondLoader } from "../../utility/Loader";


const VitelServicePlan = () => {
     const [isLoggedIn, setIsLoggedIn] = useState(false);
     const [productServices, setProductServices] = useState([]);
     const [productCategory, setProductCategory] = useState([]);
    //  const [selectedCategory, setSelectedCategory] = useState("");
     const [expandedServices, setExpandedServices] = useState({});
     const [checkEmptyArr, setCheckEmptyArr] = useState();
     const navigation = useNavigate()

  const buyAndActivatePlan = (service) => {
    navigation(
      `/product-details/id=${service.productId}/name=${service.productName}`,
      {
        state: {
          product: service,
        },
      }
    );
  };

  const truncateDescription = (description) => {
    const words = description.split(' ');
    return words.length > 10
      ? words.slice(0, 10).join(' ') + '...'
      : description;
  };

  const toggleDescription = (index) => {
    setExpandedServices((prev) => ({
      ...prev,
      [index]: !prev[index] // Toggle the state for this specific service
    }));
  };

     const handleCategoryChange = async (e) => {
      //  setSelectedCategory(e.target.value);
       const selectedCategory = e.target.value;
        const productDetails = await vitelWirelessAxios.get(
           `/generals/getAllPublicImages`
         );

         if (selectedCategory == "all") {
           const filteredProductServOnly = productDetails.data.filter(
             (service) =>
               service.categoryName === "Data" ||
               service.categoryName === "Credit Vouchers"
           );
           const subcategoryArray = filteredProductServOnly.flatMap((item) => item.subCategoryImages);
           setCheckEmptyArr(false);
           setProductServices(subcategoryArray);
         } else {
           const filteredServices = productDetails.data.filter(
             (service) => service.productCategory == selectedCategory
           );

           if (filteredServices.length === 0) {
             setCheckEmptyArr(true);
           } else {
             setCheckEmptyArr(false);
           }

           setProductServices(filteredServices[0]?.subCategoryImages);
         }
     };

  useEffect(() => {
    const getAllProductServices = async () => {
      const productDetails = await vitelWirelessAxios.get(
        `/generals/getAllPublicImages`
      );
      const productCategory = await vitelWirelessAxios.get(
        `/generals/getAllCategories `
      );

         const filteredProductServCategory = productCategory.data.filter(
           (service) =>
             service.categoryName === "Data" ||
             service.categoryName === "Credit Vouchers"
         );

      const filteredProductServOnly = productDetails.data.filter(
        (service) =>
          service.categoryName === 'Data' ||
          service.categoryName === 'Credit Vouchers'
      );
      console.log("filteredProductServOnly", filteredProductServOnly);

      const subcategoryArray = filteredProductServOnly.flatMap((item) => item.subCategoryImages);

         if (filteredProductServOnly.length === 0) {
           setCheckEmptyArr(true);
         } else {
           setCheckEmptyArr(false);
         }

         //  console.log("productDetails", productDetails.data);
         setProductCategory(filteredProductServCategory);
         setProductServices(subcategoryArray);
       };
       getAllProductServices();
     }, []);

  return (
    <div className="parentVitelService">
      <div className="userProductDiv">
        <span className="userProductTitle">
          Airtime/Data <hr className="userProductHr" />
        </span>
        <div className='productServfilterDiv'>
          <label htmlFor='vasFilter'>filter services</label>
          <select name='vasFilter' id='' 
          onChange={handleCategoryChange}
          >
            <option value='' disabled>Select an option</option>
            <option value='all'>All</option>
            {productCategory &&
              productCategory.map((category) => (
                <option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className='serviceCardContainer'>    
        {/* <div className='parentServiceCard'>
          <div className='upperServiceCard'>
            <div className=' serviceCardImg2'>
              <img src={dataBana} alt='' />
            </div>
            <div className='serviceNameDiv'>
              <span className='categoryname'>category name</span>
              <span className='serviceName'>product name</span>
            </div>
          </div>
          <div className='lowerServiceCard'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
          </div>
          <div className='lowerServiceFooter'>
            <div className='servicePriceDiv'>
              <span className='onlyDiv'>Only</span>
              <span className='servicePriceDiv'>&#8358; 2000</span>
            </div>
            <div className='servicePriceIcon'>
              <GrNext />
            </div>
          </div>
        </div> */}
        {!productServices ? (
          <SecondLoader />
        ) : checkEmptyArr ? (
          <div className='parentServiceCard2'>   
            <img src={noProduct} alt='' />
          </div>
        ) : (
          productServices?.map((service, index) => {
            const wordCount = service.shortDescription.split(' ').length;

            return (
              <Link to="/" className='parentServiceCard' key={index}>
                <div className='upperServiceCard'>
                  <div className='serviceCardImg'>
                    <img src={service.displayImage} alt='' />
                  </div>
                  <div className='serviceNameDiv'>
                    <span className='categoryname'>{service.categoryName}</span>
                    <span className='serviceName'>{service.productName}</span>
                  </div>
                </div>
                <div className='lowerServiceCard'>
                  {service.shortDescription}
                </div>
                <div
                  // onClick={() => buyAndActivatePlan(service)}
                  className='lowerServiceFooter'
                >
                  <div className='servicePriceDiv'>
                    <span className='onlyDiv'>Only</span>
                    <span className='servicePrice'>
                      &#8358; {parseInt(service.pricePerItem).toLocaleString()}
                    </span>
                  </div>
                  <div className='servicePriceIcon'>
                    <GrNext />
                  </div>
                </div>
              </Link>
            );
          })
        )}
      </div>
    </div>
  );
};

export default VitelServicePlan;
