import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { transactionLoader } from '../../../../utility/Loader';

const PhysicalProdHisTable = ({ subscriberHistory, handleGetSubtransaction }) => {
  console.log('subscriberHistory new check', subscriberHistory);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (subscriberHistory) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [subscriberHistory]);

  console.log('subscriberHistory', subscriberHistory);

  return (
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Action</th>
          <th scope="col">Purchase Id</th>
          <th scope="col">Purchase bill</th>
          <th scope="col">Purchase date</th>
          <th scope="col">Delivery type</th>
        </tr>
      </thead>
      <tbody class="table-group-divider">
        {subscriberHistory &&
          subscriberHistory?.map((userHistory, index) => (
            <>
              <tr>
                <th scope="row">{index + 1}</th>
                <td className="optTd">
                  <div
                    onClick={() => handleGetSubtransaction(userHistory.orderId)}
                    className="transactionAction"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    View Details
                  </div>
                </td>
                <td>
                  <h4 className="productPurchaseName">{userHistory?.orderNumber}</h4>
                </td>
                <td>
                  <span>
                    {' '}
                    ₦{' '}
                    {parseInt(userHistory?.totalAmount)}
                  </span>
                </td>
                <td>
                  <span>{moment(userHistory?.createdDateTime).format('MMM Do, YYYY, h:mm A')}</span>
                </td>
                <td>
                  <span>{userHistory?.typeOfService}</span>
                </td>
              </tr>
            </>
          ))}
        {/* )} */}
      </tbody>
    </table>
  );
};

export default PhysicalProdHisTable;
